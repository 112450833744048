<template>
  <div>
    <transition name="cmi-dialog">
      <div v-if="visible" class="cmi-dialog-com">
        <div class="corner-1"></div>
        <div class="corner-2"></div>
        <div class="corner-3"></div>
        <div class="corner-4"></div>
        <div class="dialog-bg-color">
          <div class="dialog-header"></div>
        </div>
        <div class="dialog-content-box">
          <div class="dialog-header">
            <div class="driver-text">{{ TitleDriver }}</div>
            <p class="header-title" v-html="TitleHeader"></p>
            <div class="close-btn" @click="closeDialog"></div>
          </div>
          <div class="dialog-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CmiDialog',
  props: {
    TitleDriver: {
      type: String,
      default: 'Driver 2'
    },
    TitleHeader: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      visible: false
    };
  },

  components: {},

  methods: {
    closeDialog() {
      this.$emit('onClose');
      this.$Bus.$emit('DRIVER_DIALOG_CLOSE');
      this.visible = false;
    }
  }
};

</script>
<style lang='scss' scoped>
.cmi-dialog-enter-active,
.cmi-dialog-leave-active {
  transition: all 0.3s;
}
.cmi-dialog-enter,
.cmi-dialog-leave-to {
  opacity: 0;

}
.cmi-dialog-com {
  width: 1460px;
  height: 740px;
  position: fixed;
  top: calc(50% + 30px);
  left: calc((100% - 310px) / 2 + 310px);
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    z-index:2;
  }
  &:before {
    top: 0;
    box-shadow: 0 0 100px 130px rgba(1, 12,12,.4);
  }
   &:after {
    bottom: 0;
    box-shadow: 0 0 100px 190px rgba(1, 12,12,.4);
  }
  .corner-1 {
    position: absolute;
    left: -4px;
    top: -4px;
    width: 170px;
    height: 170px;
    border-width: 4px;
    border-style: solid;
    border-color: #1fc9b7 transparent transparent #1fc9b7;
  }
  .corner-2 {
    position: absolute;
    right: -4px;
    top: -4px;
    width: 170px;
    height: 170px;
    border-width: 4px;
    border-style: solid;
    border-color: #1fc9b7 #1fc9b7 transparent transparent;
  }
  .corner-3 {
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 170px;
    height: 170px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent #1fc9b7 #1fc9b7 transparent;
  }
  .corner-4 {
    position: absolute;
    left: -4px;
    bottom: -4px;
    width: 170px;
    height: 170px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent transparent #1fc9b7 #1fc9b7;
  }
  .dialog-bg-color {
    width: 100%;
    height: 100%;
    background: rgba(18, 94, 85, .96);
    position: absolute;
    z-index:1;
    .dialog-header { 
      height: 106px;
      background: linear-gradient(rgba(11,89,85,1), rgba(1, 31,33,.8));
    }
  }
  .dialog-content-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index:3;
    .dialog-header {
      height: 106px;
      display: flex;
      align-items: center;
      padding: 0 56px;
      .driver-text {
        width: 190px;
        color: #1FC9B7;
        font-size: 36px;
        display: flex;
        align-items: center;
        &:after {
          content: "";
          display: block;
          width: 12px;
          height: 14px;
          background-image: url("~@/assets/images/right-arrow.png");
          background-repeat: no-repeat;
          background-size: auto 100%;
          margin-left: 10px;
          margin-top: 2px;
        }
      }
      .header-title {
        font-size: 24px;
        color: #fff;
      }
      .close-btn {
        width: 22px;
        height: 22px;
        background-image: url('~@/assets/images/dialog-close-btn.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        &:hover {
          opacity: .8;
        }
      }
    }
    .dialog-body {
      width: 100%;
      height: calc(100% - 106px);
    }
  }
}
</style>