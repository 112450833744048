<template>
  <div class="label-line-box">
    <div class="label-title">
      <p ref="partTitle" class="title-box" v-html="partTitle"></p>
      <div class="line-box">
        <svg class="circle-box">
          <circle cx="50%" cy="50%" :r="radius1" stroke-width="0" fill="rgba(31, 199, 182, 1)" />
          <circle cx="50%" cy="50%" :r="radius2" stroke="rgba(31, 199, 182, 1)" stroke-width="2" fill="transparent" />
          <circle cx="50%" cy="50%" :r="radius3" stroke="rgba(31, 199, 182, 1)" stroke-width="1" fill="transparent" />
        </svg>
        <div ref="svgBox" class="svg-box">
          <svg class="line-svg">
            <path d="M1800 80 L1650 1 L0 1" fill="transparent"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GSAP from "gsap";
  import {
    EaselPlugin
  } from "gsap/EaselPlugin";
  GSAP.registerPlugin(EaselPlugin);

  export default {
    name: "LabelLine",
    props: {
      partTitle: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        radius1: 0,
        radius2: 0,
        radius3: 0,
      };
    },

    components: {},

    mounted() {},

    methods: {
      flashAnimation() {
        this.radius1 = 8;
        this.radius2 = 16;
        this.radius3 = 22;
      },
      playAnimation(before, after) {
        const tl1 = GSAP.timeline();
        if (before) {
          tl1.add(before)
        }
        tl1.from(this.$refs.partTitle, {
            duration: .4,
            y: -10,
            opacity: 0
          }, '+=.6')
          .to(this.$data, {
            duration: 2,
            radius1: 8,
            ease: "elastic.out(1, 0.3)",
          }, '+=0')
          .to(this.$data, {
            duration: 1,
            radius2: 16,
            ease: "elastic.out(1, 1)",
          }, '>-1.5')
          .to(this.$data, {
            duration: 1,
            radius3: 22,
            ease: "elastic.out(1, 0.8)",
          }, '>-1')
          .from(this.$refs.svgBox, {
            duration: .4,
            width: 0,
            ease: "power4.inOut",
          }, '-=2');
        if (after) {
          tl1.add(after, '-=2')
        }
      }
    },
  };
</script>
<style lang='scss' scoped>
  .label-line-box {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
    position: relative;

    .label-title {
      position: relative;
      color: rgba(31, 199, 182, 1);
      font-size: 30px;
      font-weight: bold;
      padding: 0 40px;

      .title-box {
        font-size: 36px;
        color: #1FC9B7;
        margin-top: 20px;
        padding-bottom: 10px;
      }

      .line-box {
        width: calc(100% + 150px);
        height: 80px;
        position: absolute;
        right: -150px;
        bottom: -80px;

        .svg-box {
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: absolute;
          top: 0;
          right: 0;

          .line-svg {
            width: 1800px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;

            path {
              stroke: rgba(31, 199, 182, 1);
              stroke-width: 2px;
            }
          }
        }

        .circle-box {
          width: 80px;
          height: 80px;
          position: absolute;
          bottom: -40px;
          right: -40px;
        }
      }
    }
  }
</style>