import { effectStages } from '@/utils/enums';

export default {
  state: {
    model: 'FabClean',
    menu: 'Economy',
    // model: 'FabEnhancer',
    // menu: 'Product',
    TimeLineYearCurrentStage: '',
  },
  mutations: {
    SET_MODEL(state, model) {
      state.model = model;
    },
    SET_MENU(state, menu) {
      state.menu = menu;
    },
    SET_YEAR_STAGE(state, stage) {
      if (effectStages.includes(stage)) {
        state.TimeLineYearCurrentStage = stage;
      } else {
        console.error(`Store error: stage '${stage}' is not invalid params!`)
      }
    },
    CLEAR_YEAR_STAGE(state) {
      state.TimeLineYearCurrentStage = '';
    }
  },
  actions: {
    // getDictMap({commit}){
    // },
  }
}