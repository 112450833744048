<template>
  <div id="cmi-content">
    <transition name="header">
      <Header v-if="initComplete" ref="header" />
    </transition>
    <div class="navigation">
      <transition name="sidebar">
        <SiderBar v-if="initComplete" class="side-bar-wrapper" ref="sidebar" />
      </transition>
      <transition name="timeLine">
        <TimeLine
          v-if="!timeLineFade"
          class="time-line-wrapper"
          ref="timeLine"
        />
      </transition>
    </div>
    <MainContentWrapper ref="mainContent" v-if="mainReady" />
    <audio ref="audioControl" src="@/assets/audio/soundeffect1.mp3" volume="0.1" preload>
      <!-- <source  type="audio/MP3" /> -->
    </audio>
    <DriverDialog />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./components/Header.vue";
import SiderBar from "./components/SiderBar.vue";
import TimeLine from "./components/TimeLine.vue";
import MainContentWrapper from "./components/MainContentWrapper.vue";
import DriverDialog from '@/views/FabEnhancerProduct/DriverDialog.vue';

export default {
  name: "App",
  data() {
    return {
      initComplete: false,
      mainReady: false,
      timeLineFade: true,
    };
  },

  components: {
    Header,
    SiderBar,
    TimeLine,
    MainContentWrapper,
    DriverDialog
  },

  mounted() {
    // 注册time事件
    this.registTimeLineBus();
    // 注册事件：播放声音
    const audioSrc = this.$refs.audioControl.src;
    this.$Bus.$on("PLAY_VOICE", () => {
      this.$refs.audioControl?.pause();
      this.$refs.audioControl.src = "";
      this.$refs.audioControl.src = audioSrc;
      this.$refs.audioControl?.play();
    });

    const that = this;
    setTimeout(() => {
      this.initComplete = true;
      this.timeLineFade = false;
    }, 1000);
    setTimeout(() => {
      this.mainReady = true;
      this.$nextTick(() => {
        that.$Bus.$emit("CHANGE_VIEW", that.model + that.menu);
      });
    }, 2000);
  },

  computed: {
    ...mapGetters(["model", "menu"]),
  },

  methods: {
    registTimeLineBus() {
      // 注册事件：时间线隐藏
      this.$Bus.$on("SET_TIME_LINE_HIDE", () => {
        this.timeLineFade = true;
      });
      // 注册事件：时间线展示
      this.$Bus.$on("SET_TIME_LINE_SHOW", () => {
        this.timeLineFade = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#cmi-content {
  width: 100%;
  height: 100vh;
  background-color: #000;
  .navigation {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    z-index: 300;
    pointer-events: none;
    .side-bar-wrapper {
      flex-basis: 310px;
    }
    .time-line-wrapper {
      flex-grow: 1;
    }
  }
  .header-enter-active,
  .header-leave-active {
    transition: all 0.3s cubic-bezier(0.694, 0, 0.335, 1);
  }
  .header-enter,
  .header-leave-to {
    opacity: 0;
    transform: translateY(-100px);
  }

  .sidebar-enter-active,
  .sidebar-leave-active {
    transition: all 0.3s cubic-bezier(0.694, 0, 0.335, 1);
  }
  .sidebar-enter,
  .sidebar-leave-to {
    opacity: 0;
    transform: translateX(-100px);
  }

  .timeLine-enter-active,
  .timeLine-leave-active {
    transition: transform 0.3s cubic-bezier(0.694, 0, 0.335, 1),
      opacity 0.3s cubic-bezier(0.694, 0, 0.335, 1);
  }
  .timeLine-enter,
  .timeLine-leave-to {
    opacity: 0;
    transform: translateY(100px);
  }
}
</style>
