<template>
  <div class="enter-animation-com">
    <div class="animation-default-position">
      <div ref="enterAnimation" class="enter-aimation">
        <p class="enter-main-title">{{ mainTitle }}</p>
        <p class="enter-sub-title">{{ subTitle }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import GSAP from "gsap";
import { EaselPlugin } from "gsap/EaselPlugin";
GSAP.registerPlugin(EaselPlugin);

export default {
  name: "EnterAnimationCom",
  data() {
    return {
      mainTitle: "",
      subTitle: "",
    };
  },

  components: {},

  mounted() {
    // 注册事件：view过场动画 title 开始动画
    this.$Bus.$on("ENTER_ANIMATE", ({ mainTitle, subTitle }) => {
      this.mainTitle = mainTitle;
      this.subTitle = subTitle;
      this.enterAnimate();
    });
  },

  methods: {
    enterAnimate() {
      const tl = GSAP.timeline();
      const enterAnimation = GSAP.set(this.$refs.enterAnimation, {
        y: 200,
        opacity: 0,
        visibility: "unset",
      })
      const endAnimation = GSAP.set(this.$refs.enterAnimation, {
        visibility: "hidden",
      })
      tl.add(GSAP.set(this.$el, {zIndex: 100}))
        .add(enterAnimation)
        .to(this.$refs.enterAnimation, {
          ease: "slow",
          force3D: true,
          keyframes: [
            {
              duration: 1,
              y: 20,
              opacity: 1,
            },
            {
              duration: 1,
              y: 0,
              opacity: 1,
            },
            {
              duration: 1,
              y: -180,
              opacity: 0,
            },
          ],
        })
        .add(endAnimation)
        .add(GSAP.set(this.$el, {zIndex: ''}));
    },
  },
};
</script>
<style lang='scss' scoped>
.enter-animation-com {
  width: 100%;
  height: 100%;
  padding-left: 390px;
  transform: translate3d(0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  .animation-default-position {
    height: 1px;
    .enter-aimation {
      visibility: hidden;
      color: #fff;
      -webkit-font-smoothing: antialiased;
      .enter-main-title {
        font-size: 40px;
        margin-bottom: 20px;
      }
      .enter-sub-title {
        font-size: 30px;
        color: #fff;
      }
    }
  }
}
</style>