<template>
  <div class="fab-enhancer-economy-view">
    <div class="page-title">
      <p class="title">Three distinct economic growth stages since 1950</p>
    </div>
    <div class="opacity-bg">
      <div class="sub-title">GDP growth by stages in Japan</div>
      <div class="main-charts-box">
        <DataCharts />
      </div>
    </div>
  </div>
</template>

<script>
import ViewAnimation from '@/mixins/ViewAnimation';
import DataCharts from "./components/DataCharts";
import GSAP from "gsap";
import { EaselPlugin } from "gsap/EaselPlugin";
GSAP.registerPlugin(EaselPlugin);

export default {
  name: "FabEnhancerEconomy",
  mixins: [ViewAnimation],
  data() {
    return {
      mainTitle: 'Fab Enhancer',
      subTitle: 'Economy Trend'
    };
  },

  mounted() {
  },

  components: {
    DataCharts,
  },

  methods: {
  },
};
</script>
<style lang='scss' scoped>
.fab-enhancer-economy-view {
  width: 100%;
  height: 100%;
  padding-bottom: 140px;
  .page-title {
    width: 100%;
    height: 102px;
    padding-left: 40px;
    display: flex;
    align-items: center;
    background: linear-gradient(
      90deg,
      rgba(31, 199, 182, 0.5),
      rgba(31, 199, 182, 0.1)
    );
    .title {
      color: rgba(200, 202, 202, 0.9);
      font-size: 36px;
    }
  }
  .opacity-bg {
    height: calc(100% - 102px);
    background: rgba(40, 38, 38, 0.5);
  }
  .sub-title {
    font-size: 20px;
    color: rgba(31, 199, 182, 1);
    padding: 38px 40px 30px;
  }
  .main-charts-box {
    height: calc(100% - 92px);
    padding: 20px;
  }
}
</style>