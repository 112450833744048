import { effectStages } from '@/utils/enums';

const stage1Y = [1955, 1974];
const stage2Y = [1975, 1989];
const stage3Y = [1990, 2017];

function getStagePercent(val) {
  const yearCount = stage3Y[1] - stage1Y[0] + 1;
  return (val[1] - val[0] + 1)/yearCount;
}

const getters ={
  model: (state) => {
    return state.app.model;
  },
  menu: (state) => {
    return state.app.menu;
  },
  stageYears: () => {
    return {
      stage1Y,
      stage2Y,
      stage3Y
    }
  },
  stagePercent:() => {
    const yearCount = 2017 - 1955 + 1;
    return {
      stage1: getStagePercent(stage1Y),
      stage2: getStagePercent(stage2Y),
      stage3: getStagePercent(stage3Y),
    }
  },

  TimeLineYearCurrentStage: (state) => {
    return state.app.TimeLineYearCurrentStage;
  },
  preStage: (state) => {
    const currentStage = state.app.TimeLineYearCurrentStage;
    let targetStage = currentStage;
    if (currentStage) {
      const currentIndex = effectStages.findIndex(stage => stage === currentStage);
      if (currentIndex > 0) {
        targetStage = effectStages[currentIndex - 1];
      }
    }
    return targetStage;
  },
  nextStage: (state) => {
    const currentStage = state.app.TimeLineYearCurrentStage;
    let targetStage = currentStage;
    if (currentStage) {
      const currentIndex = effectStages.findIndex(stage => stage === currentStage);
      if (currentIndex < effectStages.length - 1) {
        targetStage = effectStages[currentIndex + 1];
      }
    }
    return targetStage;
  },
}

export default getters
