var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"time-line-box"},[_c('div',{staticClass:"time-line-year",class:{ enhancer: _vm.abnormalStageModel === 'ENHANCER_PRODUCT' }},[_c('div',{staticClass:"time-line-bar",style:(_vm.timeLineBarStyle)}),(_vm.abnormalStageModel === 'ENHANCER_PRODUCT')?[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"stage-box",class:{ actived: _vm.stage1Actived, 'breath-animate': !_vm.stage1Actived }},[_c('div',{staticClass:"stage-btn",on:{"click":function($event){return _vm.setStage('stage1')}}},[_c('div',{staticClass:"year-box"},[_vm._v("1990")])])]),_c('div',{staticClass:"stage-box",class:{ actived: _vm.stage2Actived, 'breath-animate': !_vm.stage2Actived }},[_c('div',{staticClass:"stage-btn",on:{"click":function($event){return _vm.setStage('stage2')}}},[_c('div',{staticClass:"year-box"},[_vm._v("2000")])])]),_c('div',{staticClass:"stage-box",class:{
          actived: _vm.TimeLineYearCurrentStage === 'stage3',
          'breath-animate': _vm.TimeLineYearCurrentStage !== 'stage3',
        }},[_c('div',{staticClass:"stage-btn",on:{"click":function($event){return _vm.setStage('stage3')}}},[_c('div',{staticClass:"year-box"},[_vm._v("2010")])])]),_c('div',{staticClass:"stage-box",class:{
          actived: _vm.TimeLineYearCurrentStage === 'stage3',
          'breath-animate': _vm.TimeLineYearCurrentStage !== 'stage3',
        }},[_c('div',{staticClass:"stage-btn",on:{"click":function($event){return _vm.setStage('stage3')}}},[_c('div',{staticClass:"year-box"},[_vm._v("2020")])])])]:[_c('div',{staticClass:"stage-box",class:{ actived: _vm.stage1Actived, 'breath-animate': !_vm.stage1Actived },style:({ width: _vm.stage1Width + 'px' })},[_c('div',{staticClass:"stage-year-box"},[_c('div',{staticClass:"year-box"},[_vm._v("1955")]),_c('div',{staticClass:"stage-container"},[_c('div',{staticClass:"stage-btn",on:{"click":function($event){return _vm.setStage('stage1')}}},[_c('span',{staticClass:"text-box"},[_vm._v("Stage 1")])])])])]),_c('div',{staticClass:"stage-box",class:{ actived: _vm.stage2Actived, 'breath-animate': !_vm.stage2Actived },style:({ width: _vm.stage2Width + 'px' })},[_c('div',{staticClass:"stage-year-box"},[_c('div',{staticClass:"year-box"},[_vm._v("1975")]),_c('div',{staticClass:"stage-container"},[_c('div',{staticClass:"stage-btn",on:{"click":function($event){return _vm.setStage('stage2')}}},[_c('span',{staticClass:"text-box"},[_vm._v("Stage 2")])])])])]),_c('div',{staticClass:"stage-box",class:{
          actived: _vm.TimeLineYearCurrentStage === 'stage3',
          'breath-animate': _vm.TimeLineYearCurrentStage !== 'stage3',
        },style:({ width: _vm.stage3Width + 'px' })},[_c('div',{staticClass:"stage-year-box"},[_c('div',{staticClass:"year-box"},[_vm._v("1990")]),_c('div',{staticClass:"stage-container"},[_c('div',{staticClass:"stage-btn",on:{"click":function($event){return _vm.setStage('stage3')}}},[_c('span',{staticClass:"text-box"},[_vm._v("Stage 3")])])])])])]],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stage-box"},[_c('div',{staticClass:"stage-btn"},[_c('div',{staticClass:"year-box"},[_vm._v("1960")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stage-box"},[_c('div',{staticClass:"stage-btn"},[_c('div',{staticClass:"year-box"},[_vm._v("1970")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stage-box"},[_c('div',{staticClass:"stage-btn"},[_c('div',{staticClass:"year-box"},[_vm._v("1980")])])])}]

export { render, staticRenderFns }