<template>
  <div class="echart-box">
    <div class="trigger-box">
      <div
        class="trigger-item"
        :style="{ width: `${stagePercent.stage1 * 100}%` }"
      >
        <transition name="fade">
          <div v-if="activeItem === 1" class="trigger-item-bg"></div>
        </transition>
      </div>
      <div
        class="trigger-item"
        :style="{ width: `${stagePercent.stage2 * 100}%` }"
      >
        <transition name="fade">
          <div v-if="activeItem === 2" class="trigger-item-bg"></div>
        </transition>
      </div>
      <div
        class="trigger-item"
        :style="{ width: `${stagePercent.stage3 * 100}%` }"
      >
        <transition name="fade">
          <div v-if="activeItem === 3" class="trigger-item-bg"></div>
        </transition>
      </div>
    </div>
    <v-chart class="chart" ref="myEcharts" :option="option" autoresize />
    <div class="trigger-box" @mouseleave="echartsMouseleave">
      <div class="trigger-event-box" @mouseleave="echartsMouseleave">
        <div
        class="trigger-item"
        @click="clickLockToggle"
        @mouseenter="echartsMouseover(...stageYears.stage1Y, 1)"
        :style="{ width: `${stagePercent.stage1 * 100}%` }"
      >
        <transition name="fade">
          <div v-if="activeItem === 1" class="trigger-item-detail">
            <h2>Rapid growth at 10-20%</h2>
            <ul>
              <li>Fast urbanization;</li>
              <li>Growing labor participation (female);</li>
              <li>Household electrical appliances consumption.</li>
            </ul>
          </div>
        </transition>
      </div>
      <div
        class="trigger-item"
        @click="clickLockToggle"
        @mouseenter="echartsMouseover(...stageYears.stage2Y, 2)"
        :style="{ width: `${stagePercent.stage2 * 100}%` }"
      >
        <transition name="fade">
          <div v-if="activeItem === 2" class="trigger-item-detail">
            <h2>Steady growth at 5-10%</h2>
            <ul>
              <li>GDP per capita reach 10k USD</li>
              <li>Urbanization rate reach 75%+</li>
            </ul>
          </div>
        </transition>
      </div>
      <div
        class="trigger-item"
        @click="clickLockToggle"
        @mouseenter="echartsMouseover(...stageYears.stage3Y, 3)"
        :style="{ width: `${stagePercent.stage3 * 100}%` }"
      >
        <transition name="fade">
          <div v-if="activeItem === 3" class="trigger-item-detail">
            <h2>Stagnation to slow recovery at -3-3%</h2>
            <ul>
              <li>Insecure labor market with not stable & stagnated income</li>
              <li>Value-driven, affordable good quality</li>
            </ul>
          </div>
        </transition>
      </div>
      </div>
      
    </div>
    <div class="legend-box">
      <div class="legend-item">
        <div class="legend-p legend-bg"></div>
        GDP（Billion JPY）
      </div>
      <div class="legend-item">
        <svg class="legend-p">
          <polyline
            points="0,7 12,12, 24,6 36,12 48,6 60,12 72,6"
            style="fill: none; stroke: #2fbaf7; stroke-width: 3"
          />
        </svg>
        YOY
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
  GridComponent,
  VisualMapComponent,
  VisualMapPiecewiseComponent,
  MarkLineComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import { YOYDate, GDPDate } from '@/database/chartsData';

use([
  CanvasRenderer,
  LineChart,
  BarChart,
  GridComponent,
  VisualMapComponent,
  VisualMapPiecewiseComponent,
  MarkLineComponent,
]);

function random(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}
const years = [];
const gdp = [];
const yoy = [];
for (let i = 1955; i < 2018; i++) {
  years.push(i);
  yoy.push(YOYDate[i]);
  gdp.push(GDPDate[i]);
}

export default {
  name: "DataCharts",
  data() {
    return {
      option: {},
      startYearRange: null,
      endYearRange: null,
      activeItem: null,
      watchDog: false,
      clickLock: false
    };
  },

  components: {
    VChart,
  },

  mounted() {
    this.setOption(years[0], years[years.length - 1]);
  },

  computed: {
    ...mapGetters(["stageYears", "stagePercent", 'TimeLineYearCurrentStage']),
  },

  watch: {
    TimeLineYearCurrentStage(val) {
      if (this.watchDog) return;
      switch(val) {
        case 'stage1':
          this.startYearRange = this.stageYears.stage1Y[0];
          this.endYearRange = this.stageYears.stage1Y[1];
          this.activeItem = 1;
          break;
        case 'stage2':
          this.startYearRange = this.stageYears.stage2Y[0];
          this.endYearRange = this.stageYears.stage2Y[1];
          this.activeItem = 2;
          break;
        case 'stage3':
          this.startYearRange = this.stageYears.stage3Y[0];
          this.endYearRange = this.stageYears.stage3Y[1];
          this.activeItem = 3;
          break;
        default:
          this.startYearRange = null;
          this.endYearRange = null;
          this.activeItem = null;
          break;
      }
      this.setOption(this.startYearRange, this.endYearRange);
    }
  },

  methods: {
    setOption(startYearRange = null, endYearRange = null) {
      let gdpVisualMapPieces = [];
      let yoyVisualMapPieces = [];
      if (startYearRange !== null && endYearRange !== null) {
        const startIndex = years.indexOf(startYearRange);
        const endIndex = years.indexOf(endYearRange);
        gdpVisualMapPieces = [
          {
            gte: startIndex,
            lte: endIndex,
            color: "#00C8AF",
          },
        ]
        yoyVisualMapPieces = [
          {
            gte: startIndex,
            lte: endIndex,
            color: "#00BEF7",
          },
        ]
      }
      this.option = {
        grid: {
          top: 10,
          bottom: 0,
          left: 10,
          right: 10,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: years,
          axisLabel: {
            color: (value) => {
              return value >= this.startYearRange && value <= this.endYearRange
                ? "#00C8AF"
                : "#fff";
            },
            margin: 10,
            rotate: 45,
            align: 'right',
            padding: [0, 10, 0, 0]
          },
        },
        yAxis: [
          {
            type: "value",
            offset: 10,
            splitNumber: 8,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              color: "#fff",
            },
            max: 800000,
          },
          {
            type: "value",
            offset: 10,
            splitNumber: 8,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              color: "#fff",
              formatter: "{value}%",
            },
            min: -10,
            max: 25,
          },
        ],
        visualMap: [
          {
            show: false,
            dimension: 0,
            seriesIndex: 0,
            inRange: {
              color: "#00C8AF",
            },
            outOfRange: {
              color: "#666666",
            },
            pieces: gdpVisualMapPieces,
          },
          {
            show: false,
            dimension: 0,
            seriesIndex: 1,
            inRange: {
              color: "#00BEF7",
            },
            outOfRange: {
              color: "#ffffff",
            },
            pieces: yoyVisualMapPieces,
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 4,
            data: gdp,
          },
          {
            name: "Electricity",
            type: "line",
            yAxisIndex: 1,
            symbol: "none",
            data: yoy,
            itemStyle: {
              show: false,
            },
            lineStyle: {
              width: 1,
            },
          },
        ],
      };
    },
    echartsMouseover(start, end, index) {
      this.startYearRange = start;
      this.endYearRange = end;
      this.activeItem = index;
      this.watchDog = true;
      this.$store.commit("SET_YEAR_STAGE", `stage${index}`);
      this.setOption(start, end);
      this.$nextTick(() => {
        this.watchDog = false;
      })
      
    },
    echartsMouseleave(e) {
      if (this.clickLock) return;
      this.startYearRange = null;
      this.endYearRange = null;
      this.activeItem = null;
      this.$store.commit("CLEAR_YEAR_STAGE");
      this.setOption();
    },
    clickLockToggle() {
      this.$Bus.$emit('PLAY_VOICE');
      this.clickLock = !this.clickLock;
    }
  },
};
</script>
<style lang='scss' scoped>
.echart-box {
  width: 100%;
  height: 100%;
  position: relative;
  .chart {
    width: 100%;
    height: calc(100% - 60px);
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }
  .trigger-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 47px 100px 62px;
    display: flex;
    .trigger-event-box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-basis: 100%;
    }
    .trigger-item {
      height: 100%;
      cursor: pointer;
    }
    .trigger-item-detail {
      height: 100%;
      color: #fff;
      padding: 20px;
      letter-spacing: 2px;
      h2 {
        margin-bottom: 10px;
        font-size: 18px;
      }
      ul {
        li {
          margin-bottom: 10px;
          font-size: 12px;
          padding-left: 12px;
          position: relative;
          &:before {
            position: absolute;
            left:0;
            top: 5px;
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #fff;
            vertical-align: middle;
          }
        }
      }
    }
    .trigger-item-bg {
      height: 100%;
      background: linear-gradient(#007169, #0a2221);
    }
  }
  .legend-box {
    display: flex;
    color: #fff;
    font-size: 14px;
    justify-content: flex-end;
    margin-top: 40px;
    .legend-item {
      display: flex;
      align-items: center;
      letter-spacing: 2px;
      margin-left: 20px;
      .legend-p {
        width: 72px;
        height: 14px;
        margin-right: 10px;
      }
      .legend-bg {
        background: rgba(31, 199, 182, 1);
      }
      .legend-svg {
      }
    }
  }
}
</style>