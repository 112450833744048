import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Bus from './utils/bus'
import '@/assets/style/reset.scss'

Vue.use(Bus);

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#cmi-content')