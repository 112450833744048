<!--  -->
<template>
  <div class="pre-next-container">
    <div class="pre-btn-box" >
      <div v-if="preBtnShow" @click="preBtn" class="btn pre-btn"></div>
    </div>
    <div class="next-btn-box">
      <div v-if="nextBtnShow" @click="nextBtn" class="btn next-btn"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { effectStages } from '@/utils/enums';

export default {
  name: 'PreNextCom',
  data () {
    return {
    };
  },

  components: {},

  mounted() {

  },

  computed: {
    ...mapGetters(["TimeLineYearCurrentStage", "preStage", "nextStage"]),
    preBtnShow() {
      return this.preStage !== this.TimeLineYearCurrentStage;
    },
    nextBtnShow() {
      return this.nextStage !== this.TimeLineYearCurrentStage;
    },
  },

  methods: {
    preBtn() {
      this.$Bus.$emit('PRE_EVENT', this.preStage);
    },
    nextBtn() {
      this.$Bus.$emit('NEXT_EVENT', this.nextStage);
    },
  }
};

</script>
<style lang='scss' scoped>
.pre-next-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 310px;
  display: flex;
  justify-content: space-between;
  z-index: 200;
  pointer-events: none;
  .btn {
    width: 45px;
    height: 58px;
    background: url('~@/assets/images/right-arrow.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    cursor: pointer;
  }
  .pre-btn-box {
    width: 80px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    pointer-events: auto;
    // cursor: w-resize;
    .pre-btn {
      transform: rotate(-180deg);
    }
    
  }
  .next-btn-box {
    width: 90px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    pointer-events: auto;
    // cursor: e-resize;

  }
}
</style>