<template>
  <div class="impact-content-com">
    <div class="page-title">
      <p class="title">{{ mainTitle }}</p>
    </div>
    <div class="main-content">
      <div class="img-container">
        <div class="img-box" :style="{
          backgroundImage
        }"></div>
        <template v-if="subTitle === 'Stage 2' || subTitle === 'Stage 3'">
          <!-- <div class="another-point" @click="showMore"></div> -->
          <WaveButton class="another-point" @click="showMore" />
          <transition name="point-result">
            <div v-if="canShow && subTitle === 'Stage 2'" class="point-result-stage-2"></div>
            <div v-if="canShow && subTitle === 'Stage 3'" class="point-result-stage-3"></div>
          </transition>
        </template>
      </div>
      <div class="sub-title">
        <div class="triangle-box"></div>
        <p class="title">{{ subTitle }} :&nbsp;&nbsp; {{ partTitle }}</p>
      </div>
      <div class="label-container">
        <LabelLine ref="LabelLine" />
        <div ref="mainInfo" class="main-item-info">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LabelLine from './LabelLine';
import GSAP from "gsap";
import { EaselPlugin } from "gsap/EaselPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import WaveButton from '@/components/WaveButton';

GSAP.registerPlugin(EaselPlugin, TextPlugin);

export default {
  name: 'ImpactContentCom',
  props: {
    firstNoAnimation: {
      type: Boolean,
      defalut: false
    },
    mainTitle: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    partTitle: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      canShow: false
    };
  },

  components: {
    LabelLine,
    WaveButton
  },

  mounted() {
    if (!this.firstNoAnimation) {
      const tl = GSAP.timeline({
        duration: .6
      });
      tl.from(this.$refs.mainInfo, {
          duration: .3,
          opacity: 0,
          y: 20
        }, '-=1')
      this.$slots.default.forEach(item => {
        const animate = GSAP.fromTo(item.elm, {
          text: {
            value: "",
          },
          display: 'none'
        }, {
          display: 'flex',
          text: {
              value: item.elm.innerHTML,
              delimiter: ""
          },
        })
        tl.add(animate);
      })
      this.$refs.LabelLine.playAnimation(null, tl);
    } else {
      this.$refs.LabelLine.flashAnimation();
    }
  },

  computed: {
    backgroundImage() {
      return `url(${this.img})`;
    }
  },

  methods: {
    showMore() {
      this.canShow = !this.canShow;
    }
  }
};

</script>
<style lang='scss' scoped>
.impact-content-com {
  width: 100%;
  height: 100%;
  .page-title {
    width: 100%;
    height: 102px;
    padding-left: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    background: linear-gradient(90deg, rgba(31, 199, 182, .5), rgba(31, 199, 182, .1));
    .title {
      color: rgba(200, 202, 202, .9);
      font-size: 36px;
    }
  }
  .main-content {
    width: 930px;
    position: relative;
    .img-container {
      width: 860px;
      height: 924px;
      position: absolute;
      top: -170px;
      right: -722px;
      z-index: -1;
      overflow: hidden;
      .img-box {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat ;
        background-position: center center;
      }
      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      &:before {
        width: 100%;
        height: 282px;
        top: -80px;
        background: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,.9), rgba(0,0,0,.7), rgba(0,0,0,.05));
      }
      &:after {
        top: -20px;
        left: -48px;
        width: 260px;
        height: 100%;
        background: linear-gradient(90deg, rgba(0,0,0,1), rgba(0,0,0,.9), rgba(0,0,0,.7), rgba(0,0,0,.05));
      }
      @keyframes timeLineAnimate {
        0% {
          opacity: .5;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: .5;
        }
      }
      .another-point {
        width: 200px;
        height: 100px;
        background-image: url("~@/assets/images/another-point.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        bottom: 200px;
        right: 180px;
        cursor: pointer;
        ::v-deep .wave-btn {
          left: 30%;
          top: 38%;
        }
      }
      .point-result-enter-active,
      .point-result-leave-active {
        transition: all 0.3s cubic-bezier(0.694, 0, 0.335, 1);
      }
      .point-result-enter,
      .point-result-leave-to {
        opacity: 0;
        transform: translateY(-10px);
      }
      .point-result-stage-2 {
        width: 400px;
        height: 220px;
        background-image: url("~@/assets/images/2091634739916_.pic_hd.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        bottom: 300px;
        right: 300px;
        z-index: 100;
      }
      .point-result-stage-3 {
        width: 400px;
        height: 220px;
        background-image: url("~@/assets/images/3041634957431_.pic_hd.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        bottom: 300px;
        right: 300px;
        z-index: 100;
      }
    }
    
    .sub-title {
      width: 100%;
      // height: 64px;
      padding: 10px 0;
      padding-left: 40px;
      display: flex;
      align-items: center;
      background: linear-gradient(90deg, rgba(31, 199, 182, .5), rgba(31, 199, 182, .1));
      .title {
        color: #fff;
        font-size: 40px;
      }
      .triangle-box {
        width: 22px;
        height: 26px;
        background: url('~@/assets/images/right-arrow.png');
        background-repeat: no-repeat;
        background-size: auto 100%;
        margin-right: 40px;
      }
    }
    .label-container {
      padding-left: 64px;
      .main-item-info {
        width: calc(100% - 138px);
        margin-top: 14px;
        background: rgba(40, 38, 37, .8);
        padding: 30px 40px;
      }
    }
    
  }
  
}
</style>