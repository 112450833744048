<template>
  <div class="dialog-content">
      <!-- hover-bg -->
      <div class="hover-event animate-bg-group">
        <transition name="bg-1">
          <div v-if="currentIndex === 1" class="hover-bg hover-bg-1"></div>
        </transition>
        <transition name="bg-2">
          <div v-if="currentIndex === 2" class="hover-bg hover-bg-2"></div>
        </transition>
        <transition name="bg-3">
          <div v-if="currentIndex === 3" class="hover-bg hover-bg-3"></div>
        </transition>
      </div>
      <!-- 背景线 -->
      <div class="grid-line-container">
        <div
          v-for="item in 3"
          :key="'vertical' + item"
          :class="['vertical-line', `vertical-${item}`]"
        ></div>
        <div
          v-for="item in 4"
          :key="'horizontal' + item"
          :class="['horizontal-line', `horizontal-${item}`]"
        ></div>
      </div>
      <!-- 时间线 -->
      <div class="time-line">
        <div class="time-item time-item-1">
          <span class="time-text">2010</span>
        </div>
        <div class="time-item time-item-2">
          <span class="time-text">2015</span>
        </div>
        <div class="time-item time-item-3">
          <span class="time-text">2018</span>
        </div>
      </div>
      <!-- 主要内容 -->
      <div class="main-content">
        <!-- table 行 head -->
        <div class="dialog-row-head-img row-1"></div>
        <div class="dialog-row-head-img row-2"></div>
        <div class="dialog-row-head-img row-3"></div>
        <!-- img-item -->
        <!-- 1-1 -->

        <div class="image-item image-item-1" :class="{actived: showIndex.includes(1)}"><p class="image-info">2009.<br/>Flare Fragrance<br/>(from Humming Flare) </p></div>
        <div class="image-item image-item-2" :class="{actived: showIndex.includes(1)}"><p class="image-info">2014.<br/>Humming Fine<br/>(from Humming)</p></div>
        <!-- 2-1 -->
        <div class="image-item image-item-3" :class="{actived: showIndex.includes(1)}"><p class="image-info">2011.<br/>Lion Aroma Rich</p></div>
        <div class="image-item image-item-4" :class="{actived: showIndex.includes(1)}"><p class="image-info">2013.<br/>Soflan Fragrance and Deodorant</p></div>
        <!-- 3-1 -->
        <div class="image-item image-item-5" :class="{actived: showIndex.includes(1)}"><p class="image-info">2009.<br/>Lenor Happiness<br/>with magic beads</p></div>
        <div class="image-item image-item-6" :class="{actived: showIndex.includes(1)}"><p class="image-info">2011.<br/>Fragrance beads</p></div>
        <div class="image-item image-item-7" :class="{actived: showIndex.includes(1)}"><p class="image-info">2009.<br/>Lenor Plus</p></div>
        <!-- 1-2 -->
        <div class="image-item image-item-8" :class="{actived: showIndex.includes(2)}"><p class="image-info">2016.<br/>Humming Flare<br/>(all day fresh)</p></div>
        <div class="image-item image-item-9" :class="{actived: showIndex.includes(2)}"><p class="image-info">2018.<br/>Flare Fragrance Iroka</p></div>
        <!-- 2-2 -->
        <div class="image-item image-item-10" :class="{actived: showIndex.includes(2)}"><p class="image-info">2015-2016.<br/>-Nano-deodorizing<br/>-For Men</p></div>
        <!-- 3-2 -->
        <div class="image-item image-item-11" :class="{actived: showIndex.includes(3)}"><p class="image-info">2016.<br/>Lenor Full-scale deodorant</p></div>
        <div class="image-item image-item-12" :class="{actived: showIndex.includes(3)}"><p class="image-info">2018.<br/>Lenor Happiness<br/>three-line development</p></div>
        <!-- 1-3 -->
        <div class="image-item image-item-13" :class="{actived: showIndex.includes(2)}"><p class="image-info">2015.<br/>Resesh strong deodorizing spray<br/>(for men/tough odor)</p></div>
        <div class="image-item image-item-14" :class="{actived: showIndex.includes(2)}"><p class="image-info">2017.<br/>Deo EX/Indoor Ex</p></div>
        <div class="image-item image-item-15" :class="{actived: showIndex.includes(2)}"><p class="image-info">2018.<br/>Humming Fine instant dry</p></div>
        <!-- 2-3 -->
        <div class="image-item image-item-16" :class="{actived: showIndex.includes(2)}"><p class="image-info">2018.<br/>Softlan Deo</p></div>
        <!-- 3-3 -->
        <div class="image-item image-item-17" :class="{actived: showIndex.includes(3)}"><p class="image-info">2015.<br/>Febreze Men/Sports</p></div>
      </div>
      <!-- hover-bg -->
      <div class="hover-event">
        <div class="hover-bg hover-bg-1">
          <WaveButton v-if="currentIndex === 1" class="view-more-btn" @click="showMore(1)" />
        </div>
        <div class="hover-bg hover-bg-2">
          <WaveButton v-if="currentIndex === 2" class="view-more-btn" @click="showMore(2)" />
        </div>
        <div class="hover-bg hover-bg-3">
          <WaveButton v-if="currentIndex === 3" class="view-more-btn" @click="showMore(3)" />
        </div>
      </div>
      <!-- 弹窗 -->
      <BorderBox
        v-if="showMoreIndex === 1"
        lineColor="#49DFE2"
        class="border-text-box text-box-1"
      >
        <div class="text-container">
          <div class="text-box">
            <p class="text-style-1">Fragrance help further drive fab enhancer pene% in JP:</p>
            <div class="text-style-1 text-style-1-none">
              <p class="text-style-2">Market driven by consumers’ needs of <span style="color: #1fc9b7;">fresh laundry/clean smell</span> on clothes</p>
              <p class="text-style-2">Leading brands established separate product line for <span style="color: #1fc9b7;">fragrant softener</span></p>
            </div>
            <div class="img-container-box">
              <p class="text-style-1">Japan softener market expanded significantly along with <span style="color: #1fc9b7;">more fragrance application</span> in products.</p>
              <div class="img-box"></div>
            </div>
          </div>
        </div>
      </BorderBox>
      <BorderBox
        v-if="showMoreIndex === 2"
        lineColor="#49DFE2"
        class="border-text-box text-box-2"
      >
        <div class="text-container">
          <div class="text-box">
            <p class="text-style-1">Fragrance story continued to evolve</p>
            <div class="text-style-1 text-style-1-none">
              <p class="text-style-2">‘<span style="color: #1fc9b7;">Smell harassment</span>’</p>
            </div>
            <div class="text-style-1 text-style-1-none">
              <p class="text-style-2">Shift focus from rich/long-lasting fragrant to <span style="color: #1fc9b7;">natural/refreshing</span> by incorporating <span style="color: #1fc9b7;">botanical ingredient</span> and <span style="color: #1fc9b7;">simpler package design</span></p>
            </div>
            <div class="img-container-box">
              <p class="text-style-1">“Smell harassment” (香害): strong fragrance used in fabric care products raised health concern in public.</p>
              <div class="img-box"></div>
            </div>
          </div>
        </div>
      </BorderBox>
      <BorderBox
        v-if="showMoreIndex === 3"
        lineColor="#49DFE2"
        class="border-text-box text-box-3"
      >
        <div class="text-container">
          <div class="text-box">
            <p class="text-style-1">Deodorant back to focus</p>
            <div class="text-style-1 text-style-1-none">
              <p class="text-style-2">P&G took lead in <span style="color: #1fc9b7;">strong deodorizing</span> with Lenor Plus since 2009</p>
            </div>
            <div class="text-style-1 text-style-1-none">
              <p class="text-style-2"><span style="color: #1fc9b7;">Technical innovation</span> for Deo trend：deep penetration, react to sweat</p>
            </div>
            <div class="text-style-1 text-style-1-none">
              <p class="text-style-2">Freshener developed into strong deodorant format for <span style="color: #1fc9b7;">men/tough odor</span></p>
            </div>
          </div>
        </div>
      </BorderBox>
    </div>
</template>

<script>
import { debounce } from "@/utils/util";

import WaveButton from '@/components/WaveButton';
import BorderBox from "@/components/BorderBox";

export default {
  name: "Driver3",
  props: ['currentDriver'],
  data () {
    return {
      showIndex: [],
      currentIndex: null,
      showMoreIndex: null,
      debounceHideMore: null,
    };
  },

  components: {
    WaveButton,
    BorderBox
  },

  mounted() {
    this.$Bus.$on("DRIVER_3_PRE", () => {
      this.prePlay();
    });
    this.$Bus.$on("DRIVER_3_NEXT", () => {
      this.nextPlay();
    });

    this.debounceHideMore = debounce(100, this.hideMore);
    window.addEventListener('click', this.debounceHideMore, true);
    this.$nextTick(() => {
      this.showIndex = [1];
      this.currentIndex = 1;
    })
  },
  
  beforeDestroy() {
    this.showIndex = [];
    this.currentIndex = null;
    this.$Bus.$off("DRIVER_3_PRE");
    this.$Bus.$off("DRIVER_3_NEXT");
    window.removeEventListener('click',  this.debounceHideMore, true);
  },

  watch: {
    currentDriver: {
      handler(val) {
        if (val === 3) {
          this.$Bus.$emit("PRE_BUTTON_NOT_DISABLED");
          if (this.currentIndex !== 3) {
            this.$Bus.$emit("NEXT_BUTTON_NOT_DISABLED");
          } else {
            this.$Bus.$emit("NEXT_BUTTON_DISABLED");
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    showMore(index) {
      if (this.showMoreIndex === index) {
        this.showMoreIndex = null;
      } else {
        setTimeout(() => {
          this.showMoreIndex = index;
        }, 200)
      }
    },
    hideMore(e) {
      if (!this.showMoreIndex) return;
      let target = e.target;
      while(target) {
        if (!(target.className.indexOf('border-text-box') > -1)) {
          target = target.parentNode
          if (target === this.$root.$el || target === null) {
            target = null
            this.showMoreIndex = null;
          }
        } else {
          target = null
        }
      }
    },
    prePlay() {
      this.$Bus.$emit('NEXT_BUTTON_NOT_DISABLED');
      if (this.currentIndex === 1) {
        this.$store.commit("SET_YEAR_STAGE", 'stage2');
      } else if (this.currentIndex === 2) {
        this.currentIndex = 1;
      } else if (this.currentIndex === 3) {
        this.currentIndex = 2;
      }
    },
    nextPlay() {
      if (this.currentIndex === 1) {
        this.currentIndex = 2;
      } else if (this.currentIndex === 2) {
        this.currentIndex = 3;
        this.$Bus.$emit('NEXT_BUTTON_DISABLED');
      }
      if (!this.showIndex.includes(this.currentIndex)) {
        this.showIndex.push(this.currentIndex);
      }
      
    },
  }
};

</script>
<style lang='scss' scoped>
.dialog-content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  @keyframes breathAnimate {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    25% {
      transform: translate(-50%, -50%) scale(0.8);
    }
    50% {
      transform: translate(-50%, -50%) scale(1);
    }
    75% {
      transform: translate(-50%, -50%) scale(0.8);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  .bg-1-leave-to,
  .bg-2-leave-to,
  .bg-3-leave-to{
    opacity: 0;
  }
  .bg-1-enter{
    transform: translateY(100%);
  }
  .bg-2-enter{
    transform: translateY(-100%);
  }
  .bg-3-enter{
    transform: translateY(100%);
  }

  .animate-bg-group {
    .hover-bg {
      transition: all 2s;
      background: linear-gradient(rgba(38,240,222, .3), rgba(1, 31, 33, .8) 180%);
    }
    .hover-bg-2 {
      width: 846px;
      height: 350px;
      background: linear-gradient(rgba(38,240,222, .3), rgba(1, 31, 33, .8) 300%);
    }
    .hover-bg-3 {
      width: 846px;
    }
  }
  .hover-bg {
    position: absolute;
    top: 20px;
    background: transparent;
    transition: all .3s;
    .view-more-btn {
      position: absolute;
      top: 30px;
      right: 90px;
    }
  }
  .hover-event {
    width: 1244px;
    height: calc(100% - 20px);
    position: absolute;
    top: 20px;
    left: 128px;
  }
  .hover-bg-1 {
    width: 400px;
    height: 100%;
    left: 0;
    top: 0;
  }
  .hover-bg-2 {
    width: 400px;
    height: 214px;
    left: 400px;
    top: 0;
  }
  .hover-bg-3 {
    width: 780px;
    height: 266px;
    left: 400px;
    top: 350px;
    .view-more-btn {
      top: 60px;
    }
  }
  .grid-line-container {
    width: 1324px;
    height: 490px;
    position: absolute;
    top: 50px;
    left: 50px;
    .vertical-line {
      position: absolute;
      top: 0;
      left: 10px;
      width: 1px;
      height: 100%;
      background: radial-gradient(
        rgba(159, 160, 160, 0.7),
        rgba(159, 160, 160, 0.04) 90%
      );
    }
    .horizontal-line {
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 90px;
      left: 0;
      background: radial-gradient(
        rgba(159, 160, 160, 0.7),
        rgba(159, 160, 160, 0.04) 90%
      );
    }
    .vertical-1 {
      left: 78px;
    }
    .vertical-2 {
      left: calc(78px + 400px);
    }
    .vertical-3 {
      left: calc(78px + 400px + 400px);
    }
    .horizontal-1 {
      top: 28px;
    }
    .horizontal-2 {
      top: calc(28px + 156px);
    }
    .horizontal-3 {
      top: calc(28px + 156px + 136px);
    }
    .horizontal-4 {
      top: calc(28px + 156px + 136px + 136px);
    }
  }
  .time-line {
    width: 1190px;
    height: 6px;
    position: absolute;
    bottom: 30px;
    left: calc(50px + 78px);
    background: #1fc9b7;
    .time-item {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #1fc9b7;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      .time-text {
        position: absolute;
        bottom: 20px;
        left: -16px;
        color: #fff;
        font-size: 22px;
        font-family: "HELV";
      }
    }
    .time-item-1 {
      left: 0;
    }
    .time-item-2 {
      left: 600px;
    }
    .time-item-3 {
      left: 1000px;
    }
  }
  .main-content {
    width: 1324px;
    height: 490px;
    position: absolute;
    top: 50px;
    left: 50px;
    .dialog-row-head-img {
      width: 60px;
      height: 40px;
      position: absolute;
      left: 6px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .row-1 {
      top: 78px;
      background-image: url('~@/assets/images/dialog/row-1.jpg');
    }
    .row-2 {
      top: 224px;
      background-image: url('~@/assets/images/dialog/row-2.jpg');
    }
    .row-3 {
      top: 360px;
      background-image: url('~@/assets/images/dialog/row-3.jpg');
    }
    .image-item {
      height: 66px;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      filter: grayscale(100%);
      opacity: .2;
      transition: all .3s;
      .image-info {
        width: 500px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        transform-origin: 0 0;
        letter-spacing: .6px;
        position: absolute;
        top: calc(100% + 8px);
        left: 50%;
        transform: translateX(-40%) scale(.8);
      }
      &.actived {
        opacity: 1;
        filter: none;
      }
    }
    @mixin image-item-common($width, $height, $top, $left, $background) {
      @if ($width != _) { width: $width + px }
      @if ($height != _) { height: $height + px }
      @if ($top != _) { top: $top + px }
      @if ($left != _) { left: $left + px }
      @if ($background != _) { background-image: url('~@/assets/images/dialog/driver3/#{$background}') }
    }
    @mixin image-bg-common($background) {
      background-image: url('~@/assets/images/dialog/driver3/#{$background}')
    }
    .image-item-1 {
      @include image-item-common(120, _, 50, 110, 'e1.png');
    }
    .image-item-2 {
      @include image-item-common(120, _, 50, 300, 'e2.png');
    }
    .image-item-3 {
      @include image-item-common(120, _, 200, 110, 'e3.png');
    }
    .image-item-4 {
      @include image-item-common(120, _, 200, 300, 'e4.png');
    }
    .image-item-5 {
      @include image-item-common(120, _, 340, 110, 'e5.png');
    }
    .image-item-6 {
      @include image-item-common(120, _, 340, 240, 'e6.png');
    }
    .image-item-7 {
      @include image-item-common(120, _, 340, 340, 'e7.png');
    }
    .image-item-8 {
      @include image-item-common(120, _, 50, 530, 'f1.png');
    }
    .image-item-9 {
      @include image-item-common(120, _, 50, 720, 'f2.png');
    }
    .image-item-10 {
      @include image-item-common(120, _, 200, 720, 'f3.png');
    }
    .image-item-11 {
      @include image-item-common(120, _, 340, 530, 'f4.png');
    }
    .image-item-12 {
      @include image-item-common(120, _, 340, 720, 'f5.png');
    }

    .image-item-13 {
      @include image-item-common(120, _, 50, 910, 'g1.png');
    }
    .image-item-14 {
      @include image-item-common(120, _, 50, 1056, 'g2.png');
    }
    .image-item-15 {
      @include image-item-common(120, _, 50, 1184, 'g3.png');
    }
    .image-item-16 {
      @include image-item-common(120, 80, 200, 1000, 'g4.png');
    }
    .image-item-17 {
      @include image-item-common(120, _, 340, 900, 'g5.png');
    }
    
  }
  .border-text-box {
    transition: all 0.3s;
    position: absolute;
  }
  .text-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    background: rgba(40,38,37,.9);
    line-height: 18px;
    .img-container-box {
      padding: 20px 40px;
      border: 1px solid #fff;
      margin-top: 10px;
    }
    .img-box {
      width: 330px;
      height: 140px;
      margin: 0 auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  .text-style-1 {
    padding-left: 14px;
    position: relative;
    font-size: 12px;
    color: #fff;
    letter-spacing: 1px;
    transition: all 0.3s;
    &:before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #1fc9b7;
      position: absolute;
      top: 4px;
      left: 0;
      transition: all 0.3s;
    }
  }
  .text-style-1-none {
    &:before {
      content: unset;
    }
  }
  .text-style-2 {
    padding-left: 16px;
    position: relative;
    font-size: 12px;
    color: #fff;
    letter-spacing: 1px;
    transition: all 0.3s;
    &:before {
      content: "";
      display: block;
      width: 9px;
      height: 10px;
      background-image: url("~@/assets/images/right-arrow.png");
      background-repeat: no-repeat;
      background-size: auto 100%;
      position: absolute;
      top: 4px;
      left: 0;
      transition: all 0.3s;
    }
  }
  .text-box-1 {
    width: 500px;
    top: 100px;
    left: 140px;
    .img-box {
      width: 400px;
      height: 180px;
      background-image: url("~@/assets/images/dialog/driver3/view-more-1.png");
    }
  }
  .text-box-2 {
    width: 500px;
    top: 100px;
    left: 580px;
    .img-box {
      margin: 20px 0;
      width: 400px;
      height: 180px;
      background-image: url("~@/assets/images/dialog/driver3/view-more-2.jpg");
    }
  }
  .text-box-3 {
    width: 500px;
    top: 200px;
    left: 660px;
  }
}
</style>