<template>
  <div class="fab-clean-impact-view">
    <transition name="impact-stage1">
      <ImpactContentCom
        :firstNoAnimation="firstNoAnimation"
        key="impactStage1"
        ref="impactStage1"
        v-if="stage === 'stage1'"
        mainTitle="Impact on Doing Laundry"
        subTitle="Stage 1"
        partTitle="Rapid Growth at 10-20%"
        :img="require('@/assets/images/impact_1.png')"
      >
        <p class="text-style-1 margin-bottom-20">Growing urban families</p>
        <p class="text-style-1 margin-bottom-20">
          Decreasing time for domestic chores
        </p>
        <p class="text-style-1 margin-bottom-20">Growing wages</p>
        <p class="text-style-1 margin-bottom-20">Reducing production costs</p>
        <p class="text-style-2 margin-bottom-50">
          Washing machine quickly penetrated
        </p>
        <p class="text-style-3 margin-bottom-16">
          Water pollution rises ( eutrophication )
        </p>
        <p class="text-style-2">Phosphorous and residue issue aroused</p>
      </ImpactContentCom>
    </transition>
    <transition :name="stageAnimation">
      <ImpactContentCom
        key="impactStage2"
        v-if="stage === 'stage2'"
        mainTitle="Immediate Impact On Doing Laundry"
        subTitle="Stage 2"
        partTitle="Steady growth at 5-10%"
        :img="require('@/assets/images/impact_2.png')"
      >
        <p class="text-style-3 margin-bottom-20">Middle class rises</p>
        <p class="text-style-2 margin-bottom-40">New washing machine models</p>
        <p class="text-style-3 margin-bottom-20">Mega cities with small living space</p>
        <p class="text-style-2 margin-bottom-40">Single tub, built - in dryer</p>
        <p class="text-style-1 margin-bottom-20">Aging issue lurking</p>
        <p class="text-style-1">Birth rate drops and life expectancy increases</p>
      </ImpactContentCom>
    </transition>
    <transition name="impact-stage3">
      <ImpactContentCom
        key="impactStage3"
        v-if="stage === 'stage3'"
        mainTitle="Immediate Impact On Doing Laundry"
        subTitle="Stage 3"
        partTitle=" Stagnation to slow recovery at -3-3% "
        :img="require('@/assets/images/impact_3.png')"
      >
        <p class="text-style-3 margin-bottom-20">Energy-conscious mindset</p>
        <div class="text-style-1 margin-bottom-20 flex">
          <div class="margin-right-10 text-style-4">Water saving</div>
          <div class="text-style-2">Single rinsing, single drum</div>
        </div>
        <div class="text-style-1 margin-bottom-20 flex">
          <div class="margin-right-10 text-style-4">Electricity saving</div>
          <div class="text-style-2">cold water wash mode</div>
        </div>
        <p class="text-style-3 margin-bottom-20">
          Aging ( 65yo + reach 21% in 2007 )
        </p>
        <p class="text-style-2">Senior needs</p>
      </ImpactContentCom>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ViewAnimation from '@/mixins/ViewAnimation';
import ImpactContentCom from "./components/ImpactContentCom.vue";


export default {
  name: "FabCleanImpact",
  mixins: [ViewAnimation],
  data() {
    return {
      stage: "stage1",
      mainTitle: 'Fab Clean',
      subTitle: 'Impact on Doing Laundry',
      firstNoAnimation: true,
      stageAnimation: 'impact-stage2-left'
    };
  },

  components: {
    ImpactContentCom,
  },

  computed: {
    ...mapGetters(["TimeLineYearCurrentStage", "preStage", "nextStage"]),
  },

  mounted() {
    // 注册事件：pre 按钮事件
    this.$Bus.$on('PRE_EVENT', (stage) => {
      this.$Bus.$emit('PLAY_VOICE');
      this.$store.commit("SET_YEAR_STAGE", stage);
    })
    // 注册事件：next 按钮事件
    this.$Bus.$on('NEXT_EVENT', (stage) => {
      this.$Bus.$emit('PLAY_VOICE');
      this.$store.commit("SET_YEAR_STAGE", stage);
    });
    setTimeout(() => {
      this.firstNoAnimation = false
    }, 2000);
  },

  watch: {
    TimeLineYearCurrentStage(val, oldVal) {
      if (val === 'stage1') {
        if (oldVal === 'stage2') {
          this.stageAnimation = 'impact-stage2-right';
        } else if (oldVal === 'stage3') {
          this.stageAnimation = 'impact-stage2-left';
        }
      } else if (val === 'stage2') {
        if (oldVal === 'stage1') {
          this.stageAnimation = 'impact-stage2-right';
        } else if (oldVal === 'stage3') {
          this.stageAnimation = 'impact-stage2-left';
        }
      } else if (val === 'stage3') {
        if (oldVal === 'stage2') {
          this.stageAnimation = 'impact-stage2-left';
        } else if (oldVal === 'stage1') {
          this.stageAnimation = 'impact-stage2-right';
        }
      }
      if (val) {
        setTimeout(() => {
          this.stage = val;
        }, 200)
      }
    },
  },

  methods: {
    enterAfter() {
      this.$store.commit("SET_YEAR_STAGE", 'stage1');
      this.$Bus.$emit('SHOW_PRE_NEXT_BUTTON');
      this.$Bus.$emit('TIME_CAN_CLEAR_SWITCH', false);
    },
    exitBefore() {
      this.$Bus.$emit('HIDE_PRE_NEXT_BUTTON');
      this.$Bus.$emit('TIME_CAN_CLEAR_SWITCH', true);
    }
  },
};
</script>
<style lang='scss' scoped>
.fab-clean-impact-view {
  .impact-stage1-enter-active,
  .impact-stage1-leave-active,
  .impact-stage2-left-enter-active,
  .impact-stage2-left-leave-active,
  .impact-stage2-right-enter-active,
  .impact-stage2-right-leave-active,
  .impact-stage3-enter-active,
  .impact-stage3-leave-active {
    transition: all .6s;
    position: absolute;
  }
  .impact-stage1-enter, .impact-stage1-leave-to {
    opacity: 0;
    transform: translateX(-100vw);
  }

  .impact-stage2-left-enter, .impact-stage2-left-leave-to {
    opacity: 0;
    transform: translateX(-100vw);
  }
  .impact-stage2-right-enter, .impact-stage2-right-leave-to {
    opacity: 0;
    transform: translateX(100vw);
  }

  .impact-stage3-enter, .impact-stage3-leave-to {
    opacity: 0;
    transform: translateX(100vw);
  }

  .margin-bottom-16 {
    margin-bottom: 16px;
  }
  .margin-bottom-20 {
    margin-bottom: 20px;
  }
  .margin-bottom-40 {
    margin-bottom: 40px;
  }
  .margin-bottom-50 {
    margin-bottom: 50px;
  }
  .margin-right-10 {
    margin-right: 10px;
  }
  .flex {
    display: flex;
  }
  .text-style-1 {
    padding-left: 16px;
    position: relative;
    font-size: 15px;
    color: #fff;
    letter-spacing: 1px;
    &:before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #1fc9b7;
      position: absolute;
      top: 6px;
      left: 0;
    }
  }
  @keyframes arrowBreathe {
    0% {
      transform: scale(.5);
    }
    50% {
      transform: scale(1);

    }
    100% {
      transform: scale(.5);

    }
  }
  .text-style-2 {
    padding-left: 34px;
    position: relative;
    font-size: 20px;
    color: #1fc9b7;
    letter-spacing: 1px;
    &:before {
      content: "";
      display: block;
      width: 18px;
      height: 22px;
      background: url("~@/assets/images/right-arrow.png");
      background-repeat: no-repeat;
      background-size: auto 100%;
      position: absolute;
      top: 1px;
      left: 0;
      animation: arrowBreathe 1s ease infinite;
    }
  }
  .text-style-3 {
    font-size: 20px;
    color: #fff;
    letter-spacing: 1px;
  }
  .text-style-4 {
    font-size: 20px;
  }
}
</style>