<template>
  <div class="wave-btn-com" v-on="$listeners">
    <div class="wave-btn">
      <div class="waves-1"></div>
      <div class="waves-2"></div>
      <div class="waves-3"></div>
      <div class="view-more">View More</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaveButton',
  data () {
    return {
    };
  },

  components: {},

  methods: {}
};

</script>
<style lang='scss' scoped>
.wave-btn-com {
  position: relative;
  .wave-btn {
    position: absolute;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    @keyframes wave-animate {
      0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
      100% {
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
      }
    }
    .waves-1,.waves-2,.waves-3 {
      width: 16px;
      height: 16px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .waves-1 {
      // background: red;
      animation: wave-animate 2s 0s infinite ease-out;
    }
    .waves-2 {
      // background: blue;
      animation: wave-animate 2s .6s infinite ease-out;
    }
    .waves-3 {
      // background: yellow;
      animation: wave-animate 2s 1.2s infinite ease-out;
    }
    
    .view-more {
      width: 70px;
      position: absolute;
      top: 0px;
      left: 20px;
      font-size: 12px;
      transform: scale(.9);
      color: #fff;
      transition: all .3s;
    }
    &:hover {
      .waves-1 {
        animation: wave-animate .6s 0 infinite ease-out;
      }
      .waves-2 {
        animation: wave-animate .6s 0.2s infinite ease-out;
      }
      .waves-3 {
        animation: wave-animate .6s .4s infinite ease-out;
      }
      .view-more {
        left: 24px;
      }
    }
  }
}

</style>