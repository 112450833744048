<!--  -->
<template>
  <div class="dialog-content">
    <!-- hover-bg -->
    <transition name="bg-1">
      <div v-if="showIndex === 1" class="animate-bg hover-bg hover-event"></div>
    </transition>
    <!-- 背景线 -->
    <div class="grid-line-container">
      <div
        v-for="item in 3"
        :key="'vertical' + item"
        :class="['vertical-line', `vertical-${item}`]"
      ></div>
      <div
        v-for="item in 4"
        :key="'horizontal' + item"
        :class="['horizontal-line', `horizontal-${item}`]"
      ></div>
    </div>
    <!-- 时间线 -->
    <div class="time-line">
      <div class="time-item time-item-1">
        <span class="time-text">1970s~</span>
      </div>
      <div class="time-item time-item-2">
        <span class="time-text">1990</span>
      </div>
      <div class="time-item time-item-3">
        <span class="time-text">2000</span>
      </div>
      <div class="time-item time-item-4">
        <span class="time-text">2010</span>
      </div>
    </div>
    <!-- 主要内容 -->
    <div class="main-content">
      <!-- table 行 head -->
      <div class="dialog-row-head-img row-1"></div>
      <div class="dialog-row-head-img row-2"></div>
      <div class="dialog-row-head-img row-3"></div>
      <!-- img-item -->
      <!-- 1-1 -->
      <div
        class="image-item image-item-1"
      >
        <div class="image-info">
          <p class="info-text">1962<br />first softener<br />in Japan<br />(non-concentrate)</p>
          <p class="info-text">1966<br />change name<br />‘Humming”<br />to differentiate</p>
        </div>
      </div>
      <!-- <div class="image-item image-item-2">
        <p class="image-info">
          1966<br />change name<br />‘Humming”<br />to differentiate
        </p>
      </div> -->
      <div class="image-item image-item-3">
        <p class="image-info">1988<br />Kao Humming 1/3</p>
      </div>
      <div class="image-item image-item-4">
        <p class="image-info">1990<br />Kao Touch</p>
      </div>
      <!-- 2-1 -->
      <div class="image-item image-item-5">
        <p class="image-info">
          1976-1981<br />Soflan S & Redina<br />non-concentrate softener
        </p>
      </div>
      <div class="image-item image-item-6">
        <p class="image-info">1988<br />Lion Soflan C</p>
      </div>
      <div class="image-item image-item-7">
        <p class="image-info">1989<br />Lion Soft & Dry</p>
      </div>
      <!-- 1-2 -->
      <div class="image-item image-item-8">
        <p class="image-info">2000.<br />Kao Humming<br />Anti-bacteria 1/3</p>
      </div>
      <!-- 2-2 -->
      <div class="image-item image-item-9">
        <p class="image-info">2000.<br />Lion Soft & Dry with anti-bacteria</p>
      </div>
      <!-- 3-2 -->
      <div
        class="image-item image-item-10"
        :class="{ actived: showIndex === 1 }"
      >
        <p class="image-info">
          1999.<br />Febreze deodorant for<br />hard-to-wash fabrics
        </p>
      </div>
      <!-- 1-3 -->
      <div class="image-item image-item-11">
        <p class="image-info">
          2005.<br />Kao Humming: Improved <br />softening, anti-wrinkle,
          antibacterial
        </p>
      </div>
      <div class="image-item image-item-12">
        <p class="image-info">
          2008.<br />Humming Flare Daily Soft Essence:<br />color protection,
          humid weather/indoor<br />dry, skin moisturizing
        </p>
      </div>
      <!-- 2-3 -->
      <div class="image-item image-item-13">
        <p class="image-info">2000.<br />Lion Eleguard Anti-Static Spray</p>
      </div>
      <div class="image-item image-item-14">
        <p class="image-info">
          2004<br />-Soflan Wrinkle Refreshing<br />-Softlan Day-fresh Deodorant
        </p>
      </div>
      <!-- 3-3 -->
      <div
        class="image-item image-item-15"
        :class="{ actived: showIndex === 1 }"
      >
        <p class="image-info">
          2000.<br />Febreze deodorant<br />multi-occasion
        </p>
      </div>
      <div
        class="image-item image-item-16"
        :class="{ actived: showIndex === 1 }"
      >
        <p class="image-info">
          2004.<br />P&G Lenor first<br />deodorant softener for in-wear
        </p>
      </div>
      <div class="image-item image-item-17">
        <p class="image-info">
          2008.<br />P&G Happiness with<br />long lasting scents
        </p>
      </div>
    </div>
    <!-- hover-bg -->
    <div class="hover-bg hover-event">
      <WaveButton class="view-more-btn" @click="showMore(1)" />
    </div>
    <!-- 弹窗 -->
    <BorderBox
      v-if="showMoreIndex === 1"
      lineColor="#49DFE2"
      class="border-text-box text-box-1"
    >
      <div class="text-container">
        <div class="text-box">
          <p class="text-style-1 margin-bottom-10">Deo for fabrics</p>
          <div class="text-style-1 text-style-1-none margin-bottom-10">
            <p class="text-style-2">
              1999, <span style="color: #1fc9b7">P&G</span> Febreze
              <span style="color: #1fc9b7">spray</span> brought deodorant to
              Japan for
              <span style="color: #1fc9b7">hard-to-wash fabrics</span>.
            </p>
          </div>
          <div class="text-style-1 text-style-1-none margin-bottom-10">
            <p class="text-style-2">
              2004, <span style="color: #1fc9b7">P&G</span> introduced Lenor
              with Febreze deodorant, answering needs of
              <span style="color: #1fc9b7">human odor</span> (odor on clothes
              from food, cigarettes, sweat); local brands followed.
            </p>
          </div>
        </div>
      </div>
    </BorderBox>
  </div>
</template>

<script>
import { debounce } from "@/utils/util";
import WaveButton from "@/components/WaveButton";
import BorderBox from "@/components/BorderBox";

export default {
  name: "Driver2",
  data() {
    return {
      showIndex: null,
      showMoreIndex: null,
      debounceHideMore: null,
    };
  },

  components: {
    WaveButton,
    BorderBox,
  },

  mounted() {
    this.$Bus.$emit("PRE_BUTTON_DISABLED");
    this.$Bus.$emit("NEXT_BUTTON_NOT_DISABLED");
    this.$Bus.$on("DRIVER_2_PRE", () => {
      // this.$store.commit("SET_YEAR_STAGE", 'stage1');
    });
    this.$Bus.$on("DRIVER_2_NEXT", () => {
      this.$store.commit("SET_YEAR_STAGE", "stage3");
    });

    this.debounceHideMore = debounce(100, this.hideMore);
    window.addEventListener("click", this.debounceHideMore, true);
    this.$nextTick(() => {
      this.showIndex = 1;
    });
  },

  beforeDestroy() {
    this.$Bus.$off("DRIVER_2_PRE");
    this.$Bus.$off("DRIVER_2_NEXT");
    window.removeEventListener("click", this.debounceHideMore, true);
  },

  methods: {
    showMore(index) {
      if (this.showMoreIndex === index) {
        this.showMoreIndex = null;
      } else {
        setTimeout(() => {
          this.showMoreIndex = index;
        }, 200);
      }
    },
    hideMore(e) {
      if (!this.showMoreIndex) return;
      let target = e.target;
      while (target) {
        if (!(target.className.indexOf("border-text-box") > -1)) {
          target = target.parentNode;
          if (target === this.$root.$el) {
            target = null;
            this.showMoreIndex = null;
          }
        } else {
          target = null;
        }
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog-content {
  width: 100%;
  height: 100%;
  position: relative;
  .bg-1-enter {
    transform: translateY(100%);
  }
  .animate-bg {
    transition: all 2s 0.3s !important;
    background: linear-gradient(
      rgba(38, 240, 222, 0.3),
      rgba(1, 31, 33, 0.8) 120%
    ) !important;
  }
  .hover-bg {
    position: absolute;
    background: transparent;
    transition: all 0.3s;
    .view-more-btn {
      position: absolute;
      top: 170px;
      right: 90px;
    }
  }
  .hover-event {
    width: 600px;
    height: 300px;
    position: absolute;
    top: 371px;
    left: 593px;
  }
  .grid-line-container {
    width: 1324px;
    height: 490px;
    position: absolute;
    top: 50px;
    left: 50px;
    .vertical-line {
      position: absolute;
      top: 0;
      left: 10px;
      width: 1px;
      height: 100%;
      background: radial-gradient(
        rgba(159, 160, 160, 0.7),
        rgba(159, 160, 160, 0.04) 90%
      );
    }
    .horizontal-line {
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 90px;
      left: 0;
      background: radial-gradient(
        rgba(159, 160, 160, 0.7),
        rgba(159, 160, 160, 0.04) 90%
      );
    }
    .vertical-1 {
      left: 78px;
    }
    .vertical-2 {
      left: calc(78px + 464px);
    }
    .vertical-3 {
      left: calc(78px + 464px + 240px);
    }
    .horizontal-1 {
      top: 28px;
    }
    .horizontal-2 {
      top: calc(28px + 156px);
    }
    .horizontal-3 {
      top: calc(28px + 156px + 136px);
    }
    .horizontal-4 {
      top: calc(28px + 156px + 136px + 136px);
    }
  }
  .time-line {
    width: 1190px;
    height: 6px;
    position: absolute;
    bottom: 30px;
    left: calc(50px + 78px);
    background: #1fc9b7;
    .time-item {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #1fc9b7;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      .time-text {
        position: absolute;
        bottom: 20px;
        left: -16px;
        color: #fff;
        font-size: 22px;
        font-family: "HELV";
      }
    }
    .time-item-1 {
      left: 0;
    }
    .time-item-2 {
      left: calc(464px);
    }
    .time-item-3 {
      left: calc(464px + 240px);
    }
    .time-item-4 {
      right: -16px;
    }
  }
  .main-content {
    width: 1324px;
    height: 490px;
    position: absolute;
    top: 50px;
    left: 50px;
    .dialog-row-head-img {
      width: 60px;
      height: 40px;
      position: absolute;
      left: 6px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .row-1 {
      top: 78px;
      background-image: url("~@/assets/images/dialog/row-1.jpg");
    }
    .row-2 {
      top: 224px;
      background-image: url("~@/assets/images/dialog/row-2.jpg");
    }
    .row-3 {
      top: 360px;
      background-image: url("~@/assets/images/dialog/row-3.jpg");
    }
    .image-item {
      height: 66px;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      opacity: 0.2;
      transition: all 0.3s;
      .image-info {
        width: 500px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        transform-origin: 0 0;
        letter-spacing: 0.6px;
        position: absolute;
        top: calc(100% + 8px);
        left: 50%;
        transform: translateX(-40%) scale(0.8);
      }
      &.actived {
        opacity: 1;
      }
    }
    @mixin image-item-common($width, $height, $top, $left, $background) {
      @if ($width != _) {
        width: $width + px;
      }
      @if ($height != _) {
        height: $height + px;
      }
      @if ($top != _) {
        top: $top + px;
      }
      @if ($left != _) {
        left: $left + px;
      }
      @if ($background != _) {
        background-image: url("~@/assets/images/dialog/driver1/#{$background}");
      }
    }
    @mixin image-bg-common($background) {
      background-image: url("~@/assets/images/dialog/driver1/#{$background}");
    }
    .image-item-1 {
      @include image-item-common(200, 80, 46, 94, "a11.png");
      .image-info {
        display: flex;
        justify-content: center;
        margin-left: -4px;
        .info-text {
          margin-right: 20px;
          &:last-child{
            margin:0;
          }
        }
      }
      &.actived {
        @include image-bg-common("a1.png");
      }
    }
    .image-item-2 {
      @include image-item-common(75, _, 60, 220, "a11.png");
      &.actived {
        @include image-bg-common("a1.png");
      }
    }
    .image-item-3 {
      @include image-item-common(90, _, 60, 326, "a21.png");
      &.actived {
        @include image-bg-common("a2.png");
      }
    }
    .image-item-4 {
      @include image-item-common(85, _, 60, 438, "a31.png");
      &.actived {
        @include image-bg-common("a3.png");
      }
    }
    .image-item-5 {
      @include image-item-common(77, _, 202, 150, "b11.png");
      &.actived {
        @include image-bg-common("b1.png");
      }
    }
    .image-item-6 {
      @include image-item-common(77, _, 202, 324, "b21.png");
      &.actived {
        @include image-bg-common("b2.png");
      }
    }
    .image-item-7 {
      @include image-item-common(77, _, 202, 433, "b31.png");
      &.actived {
        @include image-bg-common("b3.png");
      }
    }
    .image-item-8 {
      @include image-item-common(18, _, 60, 656, "c11.png");
      &.actived {
        @include image-bg-common("c1.png");
      }
    }
    .image-item-9 {
      @include image-item-common(24, _, 202, 654, "c21.png");
      &.actived {
        @include image-bg-common("c2.png");
      }
    }
    .image-item-10 {
      @include image-item-common(88, _, 340, 622, "c31.png");
      &.actived {
        @include image-bg-common("c3.png");
      }
    }
    .image-item-11 {
      @include image-item-common(82, _, 60, 845, "d11.png");
      &.actived {
        @include image-bg-common("d1.png");
      }
    }
    .image-item-12 {
      @include image-item-common(82, _, 60, 1066, "d21.png");
      &.actived {
        @include image-bg-common("d2.png");
      }
    }
    .image-item-13 {
      @include image-item-common(46, _, 202, 862, "d31.png");
      &.actived {
        @include image-bg-common("d3.png");
      }
    }
    .image-item-14 {
      @include image-item-common(66, _, 202, 1045, "d41.png");
      &.actived {
        @include image-bg-common("d4.png");
      }
    }
    .image-item-15 {
      @include image-item-common(106, _, 340, 842, "d51.png");
      &.actived {
        @include image-bg-common("d5.png");
      }
    }
    .image-item-16 {
      @include image-item-common(90, _, 340, 1010, "d61.png");
      &.actived {
        @include image-bg-common("d6.png");
      }
    }
    .image-item-17 {
      @include image-item-common(66, _, 340, 1175, "d71.png");
      &.actived {
        @include image-bg-common("d7.png");
      }
    }
  }
  .border-text-box {
    transition: all 0.3s;
    position: absolute;
  }
  .text-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    background: rgba(40, 38, 37, 0.9);
    line-height: 18px;
  }
  .text-style-1 {
    padding-left: 14px;
    position: relative;
    font-size: 12px;
    color: #fff;
    letter-spacing: 1px;
    transition: all 0.3s;
    &:before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #1fc9b7;
      position: absolute;
      top: 4px;
      left: 0;
      transition: all 0.3s;
    }
  }
  .text-style-1-none {
    &:before {
      content: unset;
    }
  }
  .text-style-2 {
    padding-left: 16px;
    position: relative;
    font-size: 12px;
    color: #fff;
    letter-spacing: 1px;
    transition: all 0.3s;
    &:before {
      content: "";
      display: block;
      width: 9px;
      height: 10px;
      background-image: url("~@/assets/images/right-arrow.png");
      background-repeat: no-repeat;
      background-size: auto 100%;
      position: absolute;
      top: 4px;
      left: 0;
      transition: all 0.3s;
    }
  }
  .text-box-1 {
    width: 400px;
    top: 300px;
    left: 900px;
  }
}
</style>