import GSAP from "gsap";
import { EaselPlugin } from "gsap/EaselPlugin";
GSAP.registerPlugin(EaselPlugin);

export default {
  mounted() {
    // 注册事件：view 退场动画开始
    this.$Bus.$on("VIEW_EXIT", ({ model, menu }) => {
      if (model + menu === this.$options.name) return;
      this.exitAnimate(model, menu);
    });
    this.enterAnimate();
  },
  methods: {
    exitAnimate(model, menu) {
      if (typeof this.exitBefore === 'function') {
        this.exitBefore();
      }
      const newViewName = model + menu;
      const width = this.$el.clientWidth;
      const that = this;
      const tl = GSAP.timeline();
      tl.to(this.$el, {
        force3D: true,
        opacity: 0,
        duration: 1,
        ease: "power4.out",
      })
      .eventCallback('onComplete', () => {
        that.$Bus.$emit("CHANGE_VIEW", newViewName);
        that.$store.commit("SET_MENU", menu);
      });
    },
    enterAnimate() {
      const { mainTitle, subTitle } = this;
      const width = this.$el.clientWidth;
      const height = this.$el.clientHeight;
      const tl = GSAP.timeline();
      const viewStartStatus = GSAP.set(this.$el, {
        x: 0.3 * width,
        y: -0.3 * height,
        scale: 0.4,
        opacity: 0,
      });
      this.$Bus.$emit("ENTER_ANIMATE", {
        mainTitle,
        subTitle
      });
      tl.add(viewStartStatus)
        .to(this.$el, {
          force3D: true,
          keyframes: [
            {
              duration: 2,
              y: -150,
              opacity: 1,
              ease: "slow",
            },
            {
              duration: 1,
              scale: 1,
              y: 0,
              x: 0,
              ease: "power4.out",
            },
          ],
        })
        .eventCallback("onComplete", () => {
          setTimeout(() => {
            this.$Bus.$emit("SET_TIME_LINE_SHOW");
            this.$Bus.$emit("VIEW_ANIMATE_COMPLETED");
            if (typeof this.enterAfter === 'function') {
              this.$nextTick(() => {
                this.enterAfter();
              })
            }
          }, 500)
        });
    },
  }
}