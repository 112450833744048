export const YOYDate = {
  "1955": 13,
  "1956": 14,
  "1957": 5,
  "1958": 10,
  "1959": 20,
  "1960": 17,
  "1961": 15,
  "1962": 18,
  "1963": 12,
  "1964": 16,
  "1965": 19,
  "1966": 17,
  "1967": 18,
  "1968": 10,
  "1969": 15,
  "1970": 23,
  "1971": 20,
  "1972": 10,
  "1973": 13,
  "1974": 12,
  "1975": 11,
  "1976": 10,
  "1977": 13,
  "1978": 5,
  "1979": 3,
  "1980": 7,
  "1981": 8,
  "1982": 5,
  "1983": 7,
  "1984": 8,
  "1985": 10,
  "1986": 8,
  "1987": 5,
  "1988": 7,
  "1989": 9,
  "1990": 10,
  "1991": 10,
  "1992": 8,
  "1993": 4,
  "1994": 1,
  "1995": 3,
  "1996": 5,
  "1997": 4,
  "1998": 3,
  "1999": 0,
  "2000": -2,
  "2001": 0,
  "2002": 1,
  "2003": 2,
  "2004": 3,
  "2005": 4,
  "2006": 2,
  "2007": 1,
  "2008": -2,
  "2009": -5,
  "2010": 3,
  "2011": -2,
  "2012": 1,
  "2013": 2,
  "2014": 3,
  "2015": 4,
  "2016": 2,
  "2017": 2
};
export const GDPDate = {
  "1955": 10000,
  "1956": 20000,
  "1957": 30000,
  "1958": 30000,
  "1959": 40000,
  "1960": 40000,
  "1961": 50000,
  "1962": 50000,
  "1963": 60000,
  "1964": 60000,
  "1965": 70000,
  "1966": 70000,
  "1967": 80000,
  "1968": 80000,
  "1969": 90000,
  "1970": 90000,
  "1971": 90000,
  "1972": 100000,
  "1973": 100000,
  "1974": 110000,
  "1975": 130000,
  "1976": 140000,
  "1977": 150000,
  "1978": 170000,
  "1979": 190000,
  "1980": 200000,
  "1981": 210000,
  "1982": 220000,
  "1983": 230000,
  "1984": 240000,
  "1985": 250000,
  "1986": 280000,
  "1987": 320000,
  "1988": 350000,
  "1989": 370000,
  "1990": 400000,
  "1991": 430000,
  "1992": 450000,
  "1993": 500000,
  "1994": 550000,
  "1995": 570000,
  "1996": 580000,
  "1997": 570000,
  "1998": 570000,
  "1999": 560000,
  "2000": 560000,
  "2001": 550000,
  "2002": 550000,
  "2003": 560000,
  "2004": 550000,
  "2005": 540000,
  "2006": 560000,
  "2007": 580000,
  "2008": 570000,
  "2009": 530000,
  "2010": 540000,
  "2011": 540000,
  "2012": 545000,
  "2013": 550000,
  "2014": 555000,
  "2015": 560000,
  "2016": 565000,
  "2017": 570000
};