<template>
  <CmiDialog :TitleDriver="TitleDriver" :TitleHeader="dialogTitle" ref="dialog">
    <div class="wrapper-container">
      <transition name="dialog-1">
        <Driver1 :currentDriver="currentIndex" v-if="currentIndex === 1" />
      </transition>
      <transition :name="driver2Animation">
        <Driver2 :currentDriver="currentIndex" v-if="currentIndex === 2" />
      </transition>
      <transition name="dialog-3">
        <Driver3 :currentDriver="currentIndex" v-if="currentIndex === 3" />
      </transition>
      <div v-if="!preButtonDisabled" class="btn pre-btn" @click="preOne"></div>
      <div
        v-if="!nextButtonDisabled"
        class="btn next-btn"
        @click="nextOne"
      ></div>
    </div>
  </CmiDialog>
</template>

<script>
import { mapGetters } from "vuex";
import CmiDialog from "./components/CmiDialog";
import Driver1 from "./components/driver1.vue";
import Driver2 from "./components/driver2.vue";
import Driver3 from "./components/driver3.vue";

export default {
  name: "DriverDialog",
  data() {
    return {
      dialogTitle1:
        "Establish Fabric Care Needs: <br/>Multiple fabric care functions establish the care needs in consumers’ mind",
      dialogTitle2:
        "Add Odor Control Function:<br /> Introduced by P&G, odor control needs further pushed the category usage.",
      dialogTitle3:
        "Add Fragrance: <br />from bad smell removal to adding good smell, fabric enhancer solutions further evolved.",
      currentIndex: 0,
      preButtonDisabled: false,
      nextButtonDisabled: false,
      driver2Animation: 'dialog-2-left',
      openedList: []
    };
  },

  components: {
    CmiDialog,
    Driver1,
    Driver2,
    Driver3,
  },

  computed: {
    ...mapGetters(["TimeLineYearCurrentStage"]),
    TitleDriver() {
      return "Driver " + this.currentIndex;
    },
    dialogTitle() {
      return this["dialogTitle" + this.currentIndex];
    }
  },

  mounted() {
    this.$Bus.$on("DRIVER_DIALOG_OPEN", () => {
      this.$refs.dialog.visible = true;
    });
    this.$Bus.$on("DRIVER_DIALOG_CLOSE", () => {
      this.openedList = [];
      this.preButtonDisabled = false;
      this.nextButtonDisabled = false;
      this.$refs.dialog.visible = false;
      this.currentIndex = 0;
    });
    this.$Bus.$on("CHANGE_DIALOG_INDEX", (index) => {
      this.currentIndex = index;
    });

    this.$Bus.$on("PRE_BUTTON_DISABLED", () => {
      this.preButtonDisabled = true;
    });
    this.$Bus.$on("PRE_BUTTON_NOT_DISABLED", () => {
      this.preButtonDisabled = false;
    });

    this.$Bus.$on("NEXT_BUTTON_DISABLED", () => {
      this.nextButtonDisabled = true;
    });
    this.$Bus.$on("NEXT_BUTTON_NOT_DISABLED", () => {
      this.nextButtonDisabled = false;
    });
  },

  watch: {
    TimeLineYearCurrentStage(val, oldVal) {
      if (!this.currentIndex) return;
      
      if (val === 'stage1') {
        this.driver2Animation = 'dialog-2-right';
      } else if (val === 'stage2') {
        if (oldVal === 'stage1') {
          this.driver2Animation = 'dialog-2-right';
        } else if (oldVal === 'stage3') {
          this.driver2Animation = 'dialog-2-left';
        }
      } else if (val === 'stage3') {
        this.driver2Animation = 'dialog-2-left';
      }
      setTimeout(() => {
        if (val) {
          let str = null;
          switch(val) {
            case 'stage1':
              str = 1;
              break;
            case 'stage2':
              str = 2;

              break;
            case 'stage3':
              str = 3;
              break;
          }
          this.currentIndex = str;
          if (!this.openedList.includes(this.currentIndex)) {
            this.openedList.push(this.currentIndex)
          }
        }
      }, 200)
    },
  },

  methods: {
    preOne() {
      this.$Bus.$emit("PLAY_VOICE");
      if (this.currentIndex) {
        this.$Bus.$emit(`DRIVER_${this.currentIndex}_PRE`);
      }
    },
    nextOne() {
      this.$Bus.$emit("PLAY_VOICE");
      if (this.currentIndex) {
        this.$Bus.$emit(`DRIVER_${this.currentIndex}_NEXT`);
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.wrapper-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 60px;
  .dialog-1-enter-active,
  .dialog-1-leave-active,
  .dialog-2-left-enter-active,
  .dialog-2-left-leave-active,
  .dialog-2-right-enter-active,
  .dialog-2-right-leave-active,
  .dialog-3-enter-active,
  .dialog-3-leave-active {
    transition: all 0.3s;
    position: absolute;
  }

  .dialog-1-enter,
  .dialog-1-leave-to {
    transform: translateX(-100%);
  }

  .dialog-2-left-enter,
  .dialog-2-left-leave-to {
    transform: translateX(-100%);
  }

  .dialog-2-right-enter,
  .dialog-2-right-leave-to {
    transform: translateX(100%);
  }
  .dialog-3-enter,
  .dialog-3-leave-to {
    transform: translateX(100%);
  }
  @keyframes breathAnimate {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    25% {
      transform: translate(-50%, -50%) scale(0.8);
    }
    50% {
      transform: translate(-50%, -50%) scale(1);
    }
    75% {
      transform: translate(-50%, -50%) scale(0.8);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  @keyframes breathAnimate1 {
    0% {
      transform: translate(-50%, -50%) rotate(-180deg) scale(1);
    }
    25% {
      transform: translate(-50%, -50%) rotate(-180deg) scale(0.8);
    }
    50% {
      transform: translate(-50%, -50%) rotate(-180deg) scale(1);
    }
    75% {
      transform: translate(-50%, -50%) rotate(-180deg) scale(0.8);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-180deg) scale(1);
    }
  }
  .btn {
    width: 45px;
    height: 58px;
    background: url("~@/assets/images/right-arrow.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 30px);
    &:hover {
      opacity: 0.8;
    }
  }
  .pre-btn {
    // transform: rotate(-180deg);
    animation: breathAnimate1 3s ease infinite;
    left: 60px;
  }
  .next-btn {
    animation: breathAnimate 3s ease infinite;
    right: 20px;
  }
}
</style>