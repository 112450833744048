<template>
  <div class="fab-clean-product-view">
    <div class="page-title">
      <p class="title">Product Evolvements response to consumer pains</p>
    </div>
    <p class="text-triggle-style">Consumer Pains</p>
    <div class="table-container">
      <!-- 背景线 -->
      <div class="table-grid-box">
        <div class="grid-line-container">
          <div v-for="item in 5" :key="item" class="vertical-line" :class="`line-${item}`"></div>
        </div>
      </div>
      <div class="table-content-flex">
        <div class="horizontal-line"></div>
        <!-- 横幅1 -->
        <div class="arrow-group position-box-1">
          <div
            class="text-arrow-style"
            :class="{ actived: activedStage1Stage2 }"
          >
            Residue
          </div>
          <div
            class="text-arrow-style"
            :class="{ actived: activedStage3 }"
          >
            Odor
          </div>
        </div>
        <!-- 横幅2 -->
        <div class="arrow-group position-box-2">
          <p class="text-triggle-style text-triggle-style-position">
            Product Evolvements
          </p>
          <div
            class="text-arrow-style"
            :class="{ actived: activedStage1Stage2 }"
          >
            Effective Clean without Residue
            <WaveButton @click="changeStage('stage1')" />
          </div>
          <div
            class="text-arrow-style"
            :class="{ actived: activedStage3 }"
          >
            Clean plus Anti-odor
            <WaveButton @click="changeStage('stage3')" />
          </div>
        </div>
        <!-- stage1-stage2 -->
        <div
          class="section-box stage1-stage2-box"
          :class="{ actived: activedStage1Stage2 }"
        >
          <!-- 背景 -->
          <div class="bottom-bg"></div>
          <!-- 上 左 -->
          <BorderBox
            class="border-text-box text-box-1"
            :class="{ actived: activedStage1Stage2 }"
            :lineColor="activedStage1Stage2 ? '#49DFE2' : '#898989'"
          >
            <div class="text-container">
              <div class="text-box">
                <p class="text-style-1 margin-bottom-10">
                  Less hand wash，more machine
                </p>
                <p class="text-style-1 margin-bottom-10">Dosage design</p>
                <p class="text-style-1 margin-bottom-10">Cold water</p>
                <div class="text-style-1 text-style-1-none">
                  <p class="text-style-2">Powder dissolve issue</p>
                </div>
              </div>
            </div>
          </BorderBox>
          <!-- 上 右 -->
          <BorderBox
            class="border-text-box text-box-2"
            :class="{ actived: activedStage1Stage2 }"
            :lineColor="activedStage1Stage2 ? '#49DFE2' : '#898989'"
          >
            <div class="text-container">
              <div class="text-box">
                <p class="text-style-1 margin-bottom-10">
                  Water saving mode：single rinse
                </p>
                <p class="text-style-1">Water saving models：from double-drum to single-drum</p>
              </div>
            </div>
          </BorderBox>
          <div class="img-container">
            <!--  -->
            <div class="img-box img-box-1">
              <div class="image-item image-item-1">
                <p class="image-info">Power in Paper Box</p>
              </div>
            </div>
            <!--  -->
            <div class="img-box img-box-2">
              <div class="image-item image-item-2">
                <p class="image-info">Top first liquid detergent, 1984</p>
              </div>
              <div class="cover-box">
                <div class="image-item image-item-3">
                  <p class="image-info">No phosphorus, 1980</p>
                </div>
                <div class="image-item image-item-4">
                  <p class="image-info">Power in Plastic Box</p>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="img-box img-box-3">
              <div class="image-item image-item-5">
                <p class="image-info">Enzyme top, 1995</p>
              </div>
              <div class="image-item image-item-6">
                <p class="image-info">Attack first liquid detergent, 1995</p>
              </div>
              <div class="image-item image-item-7">
                <p class="image-info">Super concentrate liquid detergent</p>
              </div>
            </div>
            <!--  -->
            <!-- <div class="img-box img-box-4">
              <div class="image-item image-item-7">
                <p class="image-info">Super concentrate liquid detergent</p>
              </div>
              <div class="image-item image-item-8">
                <p class="image-info">
                  Anti-Odor <br />
                  for <br />
                  indoor drying, 2005
                </p>
              </div>
            </div> -->
          </div>
          <div class="event-box">
            <div
              class="event-target event-1"
              @mouseover="mouseOverEvent(1)"
              @mouseleave="mouseLeaveEvent"
            ></div>
            <div
              class="event-target event-2"
              @mouseover="mouseOverEvent(2)"
              @mouseleave="mouseLeaveEvent"
            ></div>
            <div
              class="event-target event-3"
            ></div>
          </div>
        </div>
        <!-- stage3 -->
        <div
          class="section-box stage3-box"
          :class="{ actived: ['stage3'].includes(TimeLineYearCurrentStage) }"
        >
          <!-- 背景 -->
          <div class="bottom-bg"></div>
          <!-- 上 左 -->
          <BorderBox
            class="border-text-box text-box-1"
            :class="{ actived: activedStage3 }"
            :lineColor="activedStage3 ? '#49DFE2' : '#898989'"
          >
            <div class="text-container">
              <div class="text-box">
                <p class="text-style-1 margin-bottom-10">
                  WM drum type changed
                </p>
                <p class="text-style-1 text-style-1-none margin-bottom-10">
                  <span class="text-style-2">Spin speed reduced</span>
                </p>
                <p class="text-style-1 text-style-1-none margin-bottom-10">
                  <span class="text-style-2">Moist on clothes</span>
                </p>
                <p class="text-style-1">Indoor drying</p>
              </div>
            </div>
          </BorderBox>
          <!-- 上 右 -->
          <BorderBox
            class="border-text-box text-box-2"
            :class="{ actived: activedStage3 }"
            :lineColor="activedStage3 ? '#49DFE2' : '#898989'"
          >
            <div class="text-container">
              <div class="text-box">
                <p class="text-style-1 margin-bottom-10">Human Odor</p>
                <div class="text-style-1 text-style-1-none margin-bottom-10">
                  <p class="text-style-2">Body smell such as sweat</p>
                </div>
                <div class="text-style-1 text-style-1-none">
                  <p class="text-style-2">Cause by special people group（senior） or daily life occasion</p>
                </div>
              </div>
            </div>
          </BorderBox>
          <div class="img-container">
            <div class="img-box img-box-4">
              <!-- <div class="image-item image-item-7">
                <p class="image-info">Super concentrate liquid detergent</p>
              </div> -->
              <div class="image-item image-item-8">
                <p class="image-info">
                  Anti-Odor for <br />
                  indoor drying, 2005
                </p>
              </div>
            </div>
            <div class="img-box img-box-5">
              <div class="image-item image-item-9">
                <p class="image-info">Liquid detergent sales > powder, 2011</p>
              </div>
            </div>
            <div class="img-box img-box-6">
              <div class="cover-box">
                <div class="image-item image-item-10">
                  <p class="image-info">
                    Emal restage 2012 for fashion clothes
                  </p>
                </div>
                <div class="image-item image-item-11">
                  <p class="image-info">Detergent for seniors</p>
                </div>
              </div>
              <div class="cover-box">
                <div class="image-item image-item-12">
                  <p class="image-info">First Gel Ball (capsule) in Japan</p>
                </div>
                <div class="image-item image-item-13"></div>
              </div>
            </div>
          </div>
          <div class="event-box">
            <div
              class="event-target event-4"
              @mouseover="mouseOverEvent(3)"
              @mouseleave="mouseLeaveEvent"
            ></div>
            <div
              class="event-target event-5"
              @mouseover="mouseOverEvent(4)"
              @mouseleave="mouseLeaveEvent"
            ></div>
            <div
              class="event-target event-6"
              @mouseover="mouseOverEvent(5)"
              @mouseleave="mouseLeaveEvent"
            ></div>
          </div>
        </div>
        <!-- detail-1 -->
        <div
          class="detail-container detail-box-1"
          @mouseover="mouseOverEvent(1)"
          @mouseleave="mouseLeaveEvent"
          :class="{ actived: currentDialog === 1 }"
        >
          <div class="corner-1"></div>
          <div class="corner-2"></div>
          <div class="corner-3"></div>
          <div class="corner-4"></div>
          <div class="detail-box">
            <div class="detail-text-box">
              <p class="detail-title margin-bottom-13">
                Product Format Evolvement:
              </p>
              <p class="detail-item margin-bottom-10">
                From <span style="color: #1fc9b7;">powder</span> to <span style="color: #1fc9b7;">liquid</span> to solve the residue
              </p>
              <p class="detail-item margin-bottom-10">
                New format change makes <span style="color: #1fc9b7;">dosage concept</span> necessary for consumers
              </p>
            </div>
            <div class="detail-img-box">
              <div class="image-item image-item-1">
                <p class="image-info">Power in Paper Box</p>
              </div>
              <span class="iconfont iconyoujiantou"></span>
              <div class="image-item image-item-2">
                <p class="image-info">Top first liquid detergent, 1984</p>
              </div>
              <div class="image-item image-item-3">
                <p class="image-info">No phosphorus, 1980</p>
              </div>
              <div class="image-item image-item-4">
                <p class="image-info">Power in Plastic Box</p>
              </div>
            </div>
          </div>
        </div>
        <!-- detail-2 -->
        <div
          class="detail-container detail-box-2"
          @mouseover="mouseOverEvent(2)"
          @mouseleave="mouseLeaveEvent"
          :class="{ actived: currentDialog === 2 }"
        >
          <div class="corner-1"></div>
          <div class="corner-2"></div>
          <div class="corner-3"></div>
          <div class="corner-4"></div>
          <div class="detail-box">
            <div class="detail-text-box">
              <p class="detail-title margin-bottom-13">
                Product Format Evolvement:
              </p>
              <p class="detail-item margin-bottom-10">
                <span style="color: #1fc9b7;">Compact powder</span> and <span style="color: #1fc9b7;">Conc liquid</span> format to make the cleaning more
                efficient
              </p>
              <p class="detail-item margin-bottom-10">
                New format change makes <span style="color: #1fc9b7;">dosage concept</span> necessary for consumers
              </p>
            </div>
            <div class="detail-img-box">
              <div class="image-item image-item-5">
                <p class="image-info">Enzyme top, 1995</p>
              </div>
              <div class="image-item image-item-6">
                <p class="image-info">Attack first liquid detergent, 1995</p>
              </div>
              <div class="image-item image-item-7">
                <p class="image-info">Super concentrate liquid detergent</p>
              </div>
            </div>
          </div>
        </div>
        <!-- detail-3 -->
        <div
          class="detail-container detail-box-3"
          @mouseover="mouseOverEvent(3)"
          @mouseleave="mouseLeaveEvent"
          :class="{ actived: currentDialog === 3 }"
        >
          <div class="corner-1"></div>
          <div class="corner-2"></div>
          <div class="corner-3"></div>
          <div class="corner-4"></div>
          <div class="detail-box">
            <div class="detail-text-box">
              <p class="detail-item no-before" >
                <span style="color: #1fc9b7;">Deodorizing</span> through <span style="color: #1fc9b7;">bacteria control</span>; <br />removing odor caused by doing laundry in damp weather or drying indoor
              </p>
            </div>
            <div class="detail-img-box">
              <div class="detail-img-box-item">
                <h2>Kao Attack Neo</h2>
                <div class="detail-img-box-container margin-bottom-13">
                  <div
                    class="img img-1"
                    :style="`background-image: url(${require('@/assets/images/image/a1.png')});`"
                  >
                    <p>
                      2009-’11 <br />
                      Clean
                    </p>
                  </div>
                  <div
                    class="img img-2"
                    :style="`background-image: url(${require('@/assets/images/image/a2.png')});`"
                  >
                    <p>
                      2011 <br />+Anti-Bac line for fabric odor <br />+ bleach
                    </p>
                  </div>
                  <div
                    class="img img-3"
                    :style="`background-image: url(${require('@/assets/images/image/a3.png')});`"
                  >
                    <p>
                      2014 - now <br />kill odor-causing bac and prevent
                      <br />molds on washing machine
                    </p>
                  </div>
                </div>
              </div>
              <div class="detail-img-box-item">
                <h2>Kao Attack Neo</h2>
                <div class="detail-img-box-container">
                  <div
                    class="img img-1"
                    :style="`background-image: url(${require('@/assets/images/image/a4.png')});`"
                  >
                    <p>
                      2009-’11 <br />
                      Clean
                    </p>
                  </div>
                  <div
                    class="img img-2"
                    :style="`background-image: url(${require('@/assets/images/image/a5.png')});`"
                  >
                    <p>
                      2011 <br />+Anti-Bac line for fabric odor <br />+ bleach
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- detail-4 -->
        <div
          class="detail-container detail-box-4"
          @mouseover="mouseOverEvent(4)"
          @mouseleave="mouseLeaveEvent"
          :class="{ actived: currentDialog === 4 }"
        >
          <div class="corner-1"></div>
          <div class="corner-2"></div>
          <div class="corner-3"></div>
          <div class="corner-4"></div>
          <div class="detail-box">
            <div class="detail-text-box">
              <p class="detail-title margin-bottom-13">
                Y2011: Sales amount of liquid detergent surpass powder
              </p>
            </div>
            <div class="detail-img-box">
              <div class="image-item image-item-9">
                <p class="image-info">Liquid detergent sales > powder, 2011</p>
              </div>
            </div>
          </div>
        </div>
        <!-- detail-5 -->
        <div
          class="detail-container detail-box-5"
          @mouseover="mouseOverEvent(5)"
          @mouseleave="mouseLeaveEvent"
          :class="{ actived: currentDialog === 5 }"
        >
          <div class="corner-1"></div>
          <div class="corner-2"></div>
          <div class="corner-3"></div>
          <div class="corner-4"></div>
          <div class="detail-box">
            <div class="detail-text-box">
              <p class="detail-item no-before margin-bottom-13">
                Odor control in detergent extended from washing related odor to <span style="color: #1fc9b7;">human odor</span>
              </p>
            </div>
            <div class="detail-img-box">
              <div
                class="img img-1"
                :style="`background-image: url(${require('@/assets/images/image/L9.png')});`"
              >
                <p>
                  Stronger deodorizing <br />
                  (also anti-bac for hygiene) <br />
                  for seniors
                </p>
              </div>
              <div
                class="img img-2"
                :style="`background-image: url(${require('@/assets/images/image/b2.png')});`"
              >
                <p>
                  2017 For men – summer LTO <br />
                  2018 Suitable for sports <br />
                  (support young athletes)
                </p>
              </div>
              <div
                class="img img-3"
                :style="`background-image: url(${require('@/assets/images/image/L10.png')});`"
              >
                <p>Add fragrances</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ViewAnimation from "@/mixins/ViewAnimation";
import BorderBox from "@/components/BorderBox";
import WaveButton from '@/components/WaveButton';

export default {
  name: "FabCleanProduct",
  mixins: [ViewAnimation],
  data() {
    return {
      mainTitle: "Fab Clean",
      subTitle: "Product Evolvement",
      currentDialog: null,
    };
  },

  components: {
    BorderBox,
    WaveButton
  },

  computed: {
    ...mapGetters(["TimeLineYearCurrentStage", "stagePercent"]),
    activedStage1Stage2() {
      return ["stage1", "stage2"].includes(this.TimeLineYearCurrentStage);
    },
    activedStage3() {
      return ["stage3"].includes(this.TimeLineYearCurrentStage);
    },
    borderColorStage1Stage2() {
      return this.activedStage1Stage2 ? "#49DFE2" : "#898989";
    },
    borderColorStage3() {
      return this.activedStage3 ? "#49DFE2" : "#898989";
    },
    stage3Width() {
      return `calc(${
        (this.stagePercent.stage3 + this.stagePercent.stage2) * 100
      }% - 48px)`;
    },
  },

  methods: {
    enterAfter() {
      this.$Bus.$emit("SET_STAGE_MODEL_CLEAN_PRODUCT");
    },
    exitBefore() {
      this.$Bus.$emit("CLEAR_STAGE_MODEL");
    },
    changeStage(stage) {
      this.$Bus.$emit('PLAY_VOICE');
      if (this.TimeLineYearCurrentStage === stage) {
        this.$store.commit("CLEAR_YEAR_STAGE");
      } else {
        this.$store.commit("SET_YEAR_STAGE", stage);
      }
    },
    mouseOverEvent(index) {
      this.currentDialog = index;
    },
    mouseLeaveEvent() {
      this.currentDialog = null;
    },
  },
};
</script>
<style lang='scss' scoped>
@import '~@/assets/style/iconfont/iconfont.css';
.fab-clean-product-view {
  width: 100%;
  height: 100%;
  .page-title {
    width: 100%;
    height: 102px;
    padding-left: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background: linear-gradient(
      90deg,
      rgba(31, 199, 182, 0.5),
      rgba(31, 199, 182, 0.1)
    );
    .title {
      color: rgba(200, 202, 202, 0.9);
      font-size: 36px;
    }
  }
  .text-triggle-style {
    padding: 0px 26px;
    position: relative;
    font-size: 20px;
    color: #1fc9b7;
    letter-spacing: 1px;
    margin-left: 48px;
    margin-bottom: 15px;
    &:before {
      content: "";
      display: block;
      width: 12px;
      height: 15px;
      background: url("~@/assets/images/right-arrow.png");
      background-repeat: no-repeat;
      background-size: auto 100%;
      position: absolute;
      top: 5px;
      left: 0;
    }
  }
  .arrow-group {
    display: flex;
    justify-content: space-between;
    z-index: 10;
    .text-arrow-style {
      display: flex;
      align-items: center;
      color: #898989;
      font-size: 20px;
      flex-shrink: 0;
      width: calc(50% - 20px);
      height: 30px;
      padding-left: 42px;
      background-image: url("~@/assets/images/arrow-bg-gray.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transition: all 0.3s;
      position: relative;
      .wave-btn-com {
        position: absolute;
        right: 100px;
        top: 50%;
        ::v-deep .view-more {
          display: none;
        }
      }
      &.actived {
        color: #1fc9b7;
        background-image: url("~@/assets/images/arrow-bg.png");
        .wave-btn-com {
          ::v-deep .view-more {
            display: block;
          }
        }
      }
    }
  }
  .position-box-1 {
    width: 100%;
    position: absolute;
    left: 0;
    top: -36px;
  }
  .position-box-2 {
    width: 100%;
    position: absolute;
    left: 0;
    top: 190px;
  }

  .table-container {
    height: calc(100% - 162px);
    padding: 30px 0 30px;
    position: relative;
    .table-grid-box {
      width: 100%;
      height: 100%;
      padding-bottom: 80px;
      position: absolute;
      left: 0;
      top: 0;

      .grid-line-container {
        width: 100%;
        height: 100%;
        position: relative;
        .vertical-line {
          width: 1px;
          height: 100%;
          background: radial-gradient(
            rgba(38, 240, 222, 0.4),
            rgba(86, 133, 128, 0.1) 70%
          );
          position: absolute;
          top: 0;
        }
        .line-2 {
          left: 26%;
        }
        .line-3 {
          left: 57.1%;
        }
        .line-4 {
          left: 71.4%;
        }
        .line-5 {
          left: 100%;
        }
      }
    }
    .table-content-flex {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 10;
      .horizontal-line {
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 50px;
        left: 0;
        background: radial-gradient(
          rgba(38, 240, 222, 0.4),
          rgba(31, 199, 182, 0.01) 80%
        );
        z-index: 10;
      }

      .text-triggle-style-position {
        position: absolute;
        left: 0;
        top: -34px;
      }
      .margin-bottom-10 {
        margin-bottom: 10px;
      }
      .section-box {
        transition: all 0.3s;
        .text-style-1 {
          padding-left: 14px;
          position: relative;
          font-size: 12px;
          color: #898989;
          letter-spacing: 1px;
          transition: all 0.3s;
          &:before {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #898989;
            position: absolute;
            top: 4px;
            left: 0;
            transition: all 0.3s;
          }
        }
        .text-style-1-none {
          &:before {
            content: unset;
          }
        }
        .text-style-2 {
          padding-left: 16px;
          position: relative;
          font-size: 12px;
          color: #898989;
          letter-spacing: 1px;
          transition: all 0.3s;
          &:before {
            content: "";
            display: block;
            width: 9px;
            height: 10px;
            background-image: url("~@/assets/images/right-arrow-gray.png");
            background-repeat: no-repeat;
            background-size: auto 100%;
            position: absolute;
            top: 4px;
            left: 0;
            transition: all 0.3s;
          }
        }
        .border-text-box {
          // transform: scale(0.9);
          transition: all 0.3s;
        }
        .bottom-bg {
          width: 100%;
          position: absolute;
          left: 0;
          opacity: .4;
          top: 41%;
        }
      }

      .img-container {
        width: 100%;
        height: 220px;
        position: absolute;
        left: 0;
        bottom: 50px;
        padding-bottom: 40px;
        display: none;
        .img-box {
          flex: 1;
          height: 100%;
        }
        .img-box-1 {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
        }
        .img-box-2 {
          display: flex;
          flex-direction: column;
          align-items: center;
          .cover-box {
            display: flex;
            justify-content: space-between;
          }
        }
        .img-box-3,
        .img-box-4,
        .img-box-5 {
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
        .img-box-1,
        .img-box-2,
        .img-box-3 {
          flex: unset;
          width: 20%;
        }
        .img-box-3 {
          width: 50%;
          justify-content: flex-start;
          padding-left: 10%;
        }
        .img-box-6 {
          width: 50%;
          flex: unset;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          padding-right: 40px;
          .cover-box {
            display: flex;
            justify-content: space-between;
          }
        }
        .img-box-4,
        .img-box-5{
          width: 25%;
          flex: unset;
          padding: 0 20px;
        }

        .image-item {
          position: relative;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          .image-info {
            width: 100%;
            color: #fff;
            font-size: 12px;
            text-align: center;
            position: absolute;
            top: calc(100% + 6px);
            left: 50%;
            transform: translateX(-50%) scale(0.8);
          }
        }

        .image-item-1 {
          width: 143px;
          height: 140px;
          background-image: url("~@/assets/images/image/L1.png");
        }
        .image-item-2 {
          width: 130px;
          height: 98px;
          margin-bottom: 40px;
          background-image: url("~@/assets/images/image/L4.png");
          .image-info {
            width: 180px;
          }
        }
        .image-item-3 {
          width: 60px;
          height: 66px;
          margin-right: 40px;
          background-image: url("~@/assets/images/image/L3.png");
          .image-info {
            width: 100px;
          }
        }
        .image-item-4 {
          width: 70px;
          height: 66px;
          background-image: url("~@/assets/images/image/L2.png");
          .image-info {
            width: 130px;
          }
        }
        .image-item-5 {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          background-image: url("~@/assets/images/image/L5.png");
          .image-info {
            width: 100px;
          }
        }
        .image-item-6 {
          width: 80px;
          height: 80px;
          margin-left: 24px;
          background-image: url("~@/assets/images/image/L4.png");
          .image-info {
            width: 130px;
          }
        }
        .image-item-7 {
          width: 80px;
          height: 120px;
          margin-left: 20px;
          background-image: url("~@/assets/images/image/L6.png");
          .image-info {
            width: 130px;
          }
        }
        .image-item-8 {
          width: 80px;
          height: 100px;
          background-image: url("~@/assets/images/image/L7.png");
          background-position: center 40px;
          .image-info {
            width: 130px;
          }
        }
        .image-item-9 {
          width: 140px;
          height: 100px;
          background-image: url("~@/assets/images/image/L8.png");
          background-position: center bottom;
          .image-info {
            width: 130px;
          }
        }
        .image-item-10 {
          width: 60px;
          height: 80px;
          margin-bottom: 40px;
          margin-right: 40px;
          background-image: url("~@/assets/images/image/L11.png");
          .image-info {
            width: 130px;
          }
        }
        .image-item-11 {
          width: 60px;
          height: 80px;
          background-image: url("~@/assets/images/image/L12.png");
          .image-info {
            width: 130px;
          }
        }
        .image-item-12 {
          width: 70px;
          height: 50px;
          margin-right: 10px;
          background-image: url("~@/assets/images/image/L13.png");
          .image-info {
            width: 200px;
            transform: translateX(-30%) scale(0.8);
          }
        }
        .image-item-13 {
          width: 70px;
          height: 50px;
          background-image: url("~@/assets/images/image/L14.png");
        }
      }

      .stage1-stage2-box {
        width: calc(4 / 7 * 100%);
        height: 100%;
        padding-bottom: 50px;
        position: absolute;
        left: 0;
        bottom: 0;
        .text-container {
          display: flex;
          justify-content: center;
          padding: 10px 0;
        }
        .text-box-1 {
          width: 300px;
          position: absolute;
          top: 10px;
          left: 60px;
        }
        .text-box-2 {
          width: 280px;
          position: absolute;
          top: 10px;
          right: 14%;
          padding: 0 10px;
        }
        .bottom-bg {
          background: linear-gradient(90deg,rgba(38, 240, 222, 1),rgba(1, 31, 33, 0.8));
          // &:before {
          //   content: '';
          //   width: 45%;
          //   height: 100%;
          //   background: linear-gradient(90deg,rgba(38, 240, 222, 1),rgba(1, 31, 33, 0.8) 200%);
          //   position: absolute;
          //   left: 0;
          //   top: 0;
          // }
          // &:after {
          //   content: '';
          //   width: 48%;
          //   height: 100%;
          //   background: linear-gradient(90deg,rgba(38, 240, 222, 1) -100%,rgba(1, 31, 33, 0.8) 120%);
          //   position: absolute;
          //   left: 46%;
          //   top: 0;
          // }
        }
      }
      .stage3-box {
        width: calc(4 / 7 * 100%);
        height: 100%;
        padding-bottom: 50px;
        position: absolute;
        right: 0;
        bottom: 0;
        transition: all 0.3s;
        .text-container {
          display: flex;
          justify-content: center;
          padding: 10px 0;
        }
        .text-box-1 {
          width: 280px;
          position: absolute;
          top: 10px;
          left: 14%;
        }
        .text-box-2 {
          width: 380px;
          padding: 0 20px;
          position: absolute;
          top: 10px;
          right: 10px;
        }
        .event-box {
          padding-left: 20%;
          .event-target {
            flex: 1;
          }
        }
        .img-container {
          padding-left: 20%;
          justify-content: space-between;
        }
        .bottom-bg {
          background: linear-gradient(-90deg,rgba(38, 240, 222, 1),rgba(1, 31, 33, 0.8));
        }
      }
      .section-box.actived {
        background: linear-gradient(transparent, transparent 70%, transparent);
        .text-style-1 {
          color: #fff;
          &:before {
            background: #1fc9b7;
          }
        }
        .text-style-2 {
          color: #fff;
          &:before {
            background-image: url("~@/assets/images/right-arrow.png");
          }
        }
        .border-text-box {
          // transform: scale(1);
        }
        .bottom-bg {
          height: 60%;
        }
        .img-container {
          display: flex;
        }

      }
      .stage1-stage2-box.actived {
        z-index: 1;
        .image-item-8 {
          opacity: 0;
        }
        .event-target {
          pointer-events: unset;
        }
      }
      .stage3-box.actived {
        z-index: 1;
        .image-item-7 {
          opacity: 0;
        }
        .event-target {
          pointer-events: unset;
        }
      }
      // 弹窗详情
      .detail-container {
        position: absolute;
        bottom: 140px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        width: 0;
        height: 0;
        overflow: hidden;
        transition: all 0.3s ease 0.1s;
        z-index: 20;
        .corner-1 {
          position: absolute;
          left: 0;
          top: 0;
          width: 130px;
          height: 130px;
          border-width: 4px;
          border-style: solid;
          border-color: #1fc9b7 transparent transparent #1fc9b7;
        }
        .corner-2 {
          position: absolute;
          right: 0;
          top: 0;
          width: 130px;
          height: 130px;
          border-width: 4px;
          border-style: solid;
          border-color: #1fc9b7 #1fc9b7 transparent transparent;
        }
        .corner-3 {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 130px;
          height: 130px;
          border-width: 4px;
          border-style: solid;
          border-color: transparent #1fc9b7 #1fc9b7 transparent;
        }
        .corner-4 {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 130px;
          height: 130px;
          border-width: 4px;
          border-style: solid;
          border-color: transparent transparent #1fc9b7 #1fc9b7;
        }
        .detail-box {
          width: 680px;
          position: relative;
          z-index: 1;
          margin: 4px;
          height: 100%;
          background: rgba(18, 94, 85, 0.95);
          .detail-img-box {
            height: 360px;
            display: flex;
            .img{
              flex: 1;
              height: 100%;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            .image-item {
              position: relative;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              .image-info {
                width: 100%;
                color: #fff;
                font-size: 12px;
                text-align: center;
                position: absolute;
                top: calc(100% + 6px);
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
          .detail-text-box {
            padding: 10px 60px;
            height: calc(100% - 360px);
            background: rgba(1, 31, 33, 0.8);
            .margin-bottom-13 {
              margin-bottom: 13px;
            }
            .margin-bottom-10 {
              margin-bottom: 10px;
            }
            .detail-title {
              color: #1fc9b7;
              font-size: 15px;
            }
            .detail-item {
              color: #fff;
              font-size: 13px;
              position: relative;
              padding-left: 14px;
              &:before {
                content: "";
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #1fc9b7;
                position: absolute;
                top: 6px;
                left: 0;
                transition: all 0.3s;
              }
              &.no-before {
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
      .detail-box-1 {
        .detail-img-box {
          justify-content: center;
          align-items: center;
        }
        .iconyoujiantou {
          font-size: 40px;
          color: #fff;
        }
        .image-item-1 {
          width: 143px;
          height: 140px;
          margin-right: 20px;
          background-image: url("~@/assets/images/image/L1.png");
        }
        .image-item-2 {
          width: 130px;
          height: 140px;
          background-image: url("~@/assets/images/image/L4.png");
          .image-info {
            width: 180px;
          }
        }
        .image-item-3 {
          width: 130px;
          height: 140px;
          background-image: url("~@/assets/images/image/L3.png");
          .image-info {
            width: 160px;
          }
        }
        .image-item-4 {
          width: 160px;
          height: 140px;
          background-image: url("~@/assets/images/image/L2.png");
          .image-info {
            width: 130px;
          }
        }
      }
      .detail-box-2 {
        left: 35%;
        transform: translateX(-50%);
        .detail-img-box {
          justify-content: center;
          align-items: center;
        }
        .image-item-5 {
          width: 130px;
          height: 140px;
          margin-right: 80px;
          margin-bottom: 30px;
          background-image: url("~@/assets/images/image/L5.png");
          .image-info {
            width: 100px;
          }
        }
        .image-item-6 {
          width: 130px;
          height: 140px;
          margin-right: 40px;
          margin-bottom: 30px;
          background-image: url("~@/assets/images/image/L4.png");
          .image-info {
            width: 130px;
          }
        }
        .image-item-7 {
          width: 130px;
          height: 140px;
          margin-bottom: 30px;
          background-image: url("~@/assets/images/image/L6.png");
          .image-info {
            width: 130px;
          }
        }
      }
      .detail-box-3 {
        left: 60%;
        transform: translateX(-50%);
        .detail-box {
          .detail-img-box {
            height: 400px;
            display: block;
            .detail-img-box-item {
              &:first-child {
                margin-bottom: 40px;
              }
              h2 {
                color: #fff;
                padding: 10px;
              }
              .detail-img-box-container {
                display: flex;
                .img {
                  height: 100px;
                  position: relative;
                  background-size: contain;
                  p {
                    width: 100%;
                    color: #fff;
                    text-align: center;
                    font-size: 12px;
                    position: absolute;
                    top: 110px;
                  }
                }
              }
            }
          }
        }
      }
      .detail-box-4 {
        left: 70%;
        transform: translateX(-50%);
        .detail-img-box {
          justify-content: center;
          align-items: center;
        }
        .image-item-9 {
          width: 600px;
          height: 330px;
          margin-bottom: 20px;
          background-image: url("~@/assets/images/image/L8.png");
          .image-info {
            width: 130px;
          }
        }
        .detail-text-box {
          height: 50px;
          text-align: center;
        }
      }
      .detail-box-5 {
        left: unset;
        right: 0;
        .detail-box {
          .detail-img-box {
            align-items: center;
            .img {
              height: 100px;
              position: relative;
              background-size: contain;
              margin-bottom: 40px;
              p {
                width: 100%;
                color: #fff;
                text-align: center;
                font-size: 12px;
                position: absolute;
                top: 110px;
              }
            }
          }
        }
      }
      .detail-container.actived {
        width: auto;
        height: auto;
        visibility: inherit;
        bottom: 150px;
        opacity: 1;
      }

      .event-box {
        width: 100%;
        height: 200px;
        position: absolute;
        left: 0;
        bottom: 50px;
        display: flex;
        .event-target {
          cursor: pointer;
          position: relative;
          pointer-events: none;
        }
        .event-1 {
          width: 50%;
        }
        .event-2 {
          width: 37.5%;
        }
        .event-3 {
          width: 12.5%;
        }
        .event-4 {
          flex: unset !important;
          width: 25%;
        }
        .event-5 {
          flex: unset !important;
          width: 25%;
        }
        .event-6 {
          flex: unset !important;
          width: 50%;
        }
      }
    }
  }
}
</style>