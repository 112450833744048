<template>
  <div class="cmi-border-box-1">
    <svg class="cmi-border-svg-container" width="100%" height="100%">
      <defs>
        <path :id="pathId" :d="pathD" fill="transparent" />
        <radialGradient :id="gradientId" cx="50%" cy="50%" r="50%">
          <stop offset="0%" stop-color="#fff" stop-opacity="1" />
          <stop offset="60%" stop-color="#fff" stop-opacity="0" />
        </radialGradient>
        <mask :id="maskId1">
          <circle :cx="cx1" :cy="cy1" :r="maskCircleR" :fill="`url(#${gradientId})`">
          </circle>
        </mask>
        <mask :id="maskId2">
          <circle :cx="cx2" :cy="cy2" :r="maskCircleR" :fill="`url(#${gradientId})`">
          </circle>
        </mask>
      </defs>
      <rect
        x="1"
        y="1"
       :width="width >= 2 ? width - 2 : width"
       :height="height >= 2 ? height - 2 : height"
        stroke-width="0"
        fill="rgba(34, 34, 34, .5)"
      ></rect>
      <use
        :stroke="lineColor"
        stroke-width="2"
        :xlink:href="`#${pathId}`"
        :mask="`url(#${maskId1})`"
        :stroke-dasharray="strokeDasharray1"
      >
      </use>
      <use
        :stroke="lineColor"
        stroke-width="2"
        :xlink:href="`#${pathId}`"
        :mask="`url(#${maskId2})`"
        :stroke-dasharray="strokeDasharray2"
      >
      </use>
    </svg>
    <div class="border-box-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import GSAP from 'gsap';
import { EaselPlugin } from "gsap/EaselPlugin";
import { uuid, debounce, observerDomResize } from "@/utils/util";
GSAP.registerPlugin(EaselPlugin);
export default {
  name: 'BorderBox',
  props: {
    lineColor: {
      type: String,
      default: '#898989'
    }
  },
  data() {
    const id = uuid();
    return {
      width: 0,
      height: 0,
      pathId: `border-box-path-${id}`,
      gradientId: `border-box-gradient-${id}`,
      maskId1: `border-box-mask1-${id}`,
      maskId2: `border-box-mask2-${id}`,
      debounceInitWHFun: null,
      domObserver: null,
      cy1: 0,
      dash: 0
    };
  },

  components: {},

  async mounted() {
    await this.initWH();
    this.dash = this.width;
    const height = this.height;
    const tl = GSAP.timeline();
    tl.to(this.$data, {
      duration: .8,
      dash: 0,
      ease: 'power4.out'
    })
    .to(this.$data, {
      duration: .5,
      cy1: height / 2,
      ease: 'power4.out'
    })
  },

  computed: {
    pathD() {
      const { width, height } = this;
      return `M${width - 1} 1 L1 1 L1 ${height - 1} L ${width - 1} ${height - 1} Z`
    },
    maskCircleR() {
      const maxOne = (Math.max(this.width, this.height) * 2) / 3;
      // return maxOne >= 200 ? 200 : maxOne;
      return maxOne ;
    },
    cx1() {
      return this.dash;
    },
    cx2() {
      return this.width - this.dash;
    },
    cy2() {
      return this.height - this.cy1;
    },
    strokeDasharray1() {
      const { dash, cy1, width, height } = this;
      return `${width - dash + cy1 - 1}, ${(width + height - 2) * 2 - width + dash - cy1}`
    },
    strokeDasharray2() {
      const { dash, cy1, width, height } = this;
      return `0, ${width + height - 2}, ${width - dash + cy1 - 1}, ${(width + height - 2) * 2 - width + dash - cy1}`
    }
  },

  beforeDestroy() {
    this.unbindDomResizeCallback();
  },

  methods: {
    initWH() {
      return new Promise(resolve => {
        this.$nextTick(() => {
        this.width = this.$el.clientWidth;
        this.height = this.$el.clientHeight;
        resolve();
      });
      })
    },
    getDebounceInitWHFun() {
      this.debounceInitWHFun = debounce(100, this.initWH);
    },
    bindDomResizeCallback() {
      this.domObserver = observerDomResize(this.$el, this.debounceInitWHFun);
    },
    unbindDomResizeCallback() {
      if (!this.domObserver) return;

      this.domObserver.disconnect();
      this.domObserver.takeRecords();
      this.domObserver = null;
    },
  },
};
</script>
<style lang='scss' scoped>
.cmi-border-box-1 {
  position: relative;
  .cmi-border-svg-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .border-box-content {
    padding: 2px;
    position: relative;
    z-index: 1;
  }
}
</style>