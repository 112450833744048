<template>
  <div class="time-line-box">
    <div
      class="time-line-year"
      :class="{ enhancer: abnormalStageModel === 'ENHANCER_PRODUCT' }"
    >
      <div class="time-line-bar" :style="timeLineBarStyle"></div>
      <template v-if="abnormalStageModel === 'ENHANCER_PRODUCT'">
        <div class="stage-box">
          <div class="stage-btn">
            <div class="year-box">1960</div>
          </div>
        </div>
        <div class="stage-box">
          <div class="stage-btn">
            <div class="year-box">1970</div>
          </div>
        </div>
        <div class="stage-box">
          <div class="stage-btn">
            <div class="year-box">1980</div>
          </div>
        </div>
        <div
          class="stage-box"
          :class="{ actived: stage1Actived, 'breath-animate': !stage1Actived }"
        >
          <div class="stage-btn" @click="setStage('stage1')">
            <div class="year-box">1990</div>
          </div>
        </div>
        <div
          class="stage-box"
          :class="{ actived: stage2Actived, 'breath-animate': !stage2Actived }"
        >
          <div class="stage-btn" @click="setStage('stage2')">
            <div class="year-box">2000</div>
          </div>
        </div>
        <div
          class="stage-box"
          :class="{
            actived: TimeLineYearCurrentStage === 'stage3',
            'breath-animate': TimeLineYearCurrentStage !== 'stage3',
          }"
        >
          <div class="stage-btn" @click="setStage('stage3')">
            <div class="year-box">2010</div>
          </div>
        </div>
        <div
          class="stage-box"
          :class="{
            actived: TimeLineYearCurrentStage === 'stage3',
            'breath-animate': TimeLineYearCurrentStage !== 'stage3',
          }"
        >
          <div class="stage-btn" @click="setStage('stage3')">
            <div class="year-box">2020</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="stage-box"
          :class="{ actived: stage1Actived, 'breath-animate': !stage1Actived }"
          :style="{ width: stage1Width + 'px' }"
        >
          <div class="stage-year-box">
            <div class="year-box">1955</div>
            <div class="stage-container">
              <div class="stage-btn" @click="setStage('stage1')">
                <span class="text-box">Stage 1</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="stage-box"
          :class="{ actived: stage2Actived, 'breath-animate': !stage2Actived }"
          :style="{ width: stage2Width + 'px' }"
        >
          <div class="stage-year-box">
            <div class="year-box">1975</div>
            <div class="stage-container">
              <div class="stage-btn" @click="setStage('stage2')">
                <span class="text-box">Stage 2</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="stage-box"
          :class="{
            actived: TimeLineYearCurrentStage === 'stage3',
            'breath-animate': TimeLineYearCurrentStage !== 'stage3',
          }"
          :style="{ width: stage3Width + 'px' }"
        >
          <div class="stage-year-box">
            <div class="year-box">1990</div>
            <div class="stage-container">
              <div class="stage-btn" @click="setStage('stage3')">
                <span class="text-box">Stage 3</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      width: 0,
      firstMouseOver: true,
      transition: "background .3s",
      canClear: true,
      abnormalStageModel: null,
    };
  },

  components: {},

  mounted() {
    // 注册事件：时间线 可被点击清除切换
    this.$Bus.$on("TIME_CAN_CLEAR_SWITCH", (canClear) => {
      this.canClear = canClear;
    });
    // 注册事件：stage 模式切换
    // abnormalStageModel CLEAN_PRODUCT     fab clean product模式
    // abnormalStageModel ENHANCER_PRODUCT  fab enhancer product模式
    this.$Bus.$on("SET_STAGE_MODEL_CLEAN_PRODUCT", () => {
      this.abnormalStageModel = "CLEAN_PRODUCT";
    });
    this.$Bus.$on("SET_STAGE_MODEL_ENHANCER_PRODUCT", () => {
      this.abnormalStageModel = "ENHANCER_PRODUCT";
    });
    this.$Bus.$on("CLEAR_STAGE_MODEL", () => {
      this.abnormalStageModel = null;
    });

    this.$nextTick(() => {
      this.width = this.$el.clientWidth - 62 - 48 - 180;
    });
    setTimeout(() => {
      this.width = this.$el.clientWidth - 62 - 48 - 180;
    }, 1000);
  },

  computed: {
    ...mapGetters(["stageYears", "stagePercent", "TimeLineYearCurrentStage"]),
    stage1Width() {
      return this.stagePercent.stage1 * this.width + 62;
    },
    stage2Width() {
      return this.stagePercent.stage2 * this.width;
    },
    stage3Width() {
      return this.stagePercent.stage3 * this.width + 48;
    },
    stage1Actived() {
      const stage = this.TimeLineYearCurrentStage;
      let active = false;
      if (this.abnormalStageModel === "CLEAN_PRODUCT") {
        active = stage === "stage1" || stage === "stage2";
      } else if (this.abnormalStageModel === "ENHANCER_PRODUCT") {
        active = stage === "stage1";
      } else {
        active = stage === "stage1";
      }
      return active;
    },
    stage2Actived() {
      const stage = this.TimeLineYearCurrentStage;
      let active = false;
      if (this.abnormalStageModel === "CLEAN_PRODUCT") {
        active = !!stage;
      } else if (this.abnormalStageModel === "ENHANCER_PRODUCT") {
        active = stage === "stage2";
      } else {
        active = stage === "stage2";
      }
      return active;
    },
    timeLineBarStyle() {
      const stage = this.TimeLineYearCurrentStage;
      let width = 0;
      let left = 0;
      let background = ""; 
      if (this.abnormalStageModel === "CLEAN_PRODUCT") {
        if (stage === "stage1" || stage === "stage2") {
          width = this.stage1Width + this.stage2Width + this.stage3Width / 3;
          left = 0;
          background = "linear-gradient(90deg, #1fc9b7, transparent)";
        } else if (stage === "stage3") {
          width = this.stage3Width + this.stage2Width + this.stage1Width / 3;
          left = (this.stage1Width / 3) * 2;
          background = "linear-gradient(90deg, transparent, #1fc9b7)";
        }
      } else if (this.abnormalStageModel === "ENHANCER_PRODUCT") {
        const barWidth = this.width - 120 + 62 + 48;
        if (stage === "stage1") {
          width = barWidth / 6 * 2 + 40;
          left = barWidth / 6 * 2 + 60 - 20;
          background = "linear-gradient(90deg, transparent, #1fc9b7, transparent)";
        } else if (stage === "stage2") {
          width = barWidth / 6 * 2 + 40;
          left = barWidth / 6 * 3 + 60 - 20;
          background = "linear-gradient(90deg, transparent, #1fc9b7, transparent)";
        } else if (stage === "stage3") {
          width = barWidth / 6 * 2 + 60 + 40;
          left = barWidth / 6 * 4 + 60 - 40;
          background = "linear-gradient(90deg, transparent, #1fc9b7)";
        }
      } else {
        if (stage === "stage1") {
          width = this.stage1Width + this.stage2Width / 2;
          left = 0;
          background = "linear-gradient(90deg, #1fc9b7, transparent)";
        } else if (stage === "stage2") {
          width = this.stage2Width + this.stage1Width / 2;
          left = this.stage1Width / 2;
          background =
            "linear-gradient(90deg, transparent 10%, #1fc9b7 60%, transparent)";
        } else if (stage === "stage3") {
          width = this.stage3Width + this.stage2Width / 2;
          left = this.stage1Width + this.stage2Width / 2;
          background = "linear-gradient(90deg, transparent, #1fc9b7)";
        }
      }

      return {
        width: width + "px",
        left: left + "px",
        background,
        transition: this.transition,
      };
    },
  },

  watch: {
    TimeLineYearCurrentStage: {
      handler(val, oldVal) {
        if (!oldVal && val) {
          this.transition = "background .3s";
        } else {
          this.transition = "all .3s";
        }
      },
      immediate: true,
    },
  },

  methods: {
    setStage(stage) {
      if (this.canClear) {
         if (this.abnormalStageModel === "CLEAN_PRODUCT") {
          if (["stage1", "stage2"].includes(this.TimeLineYearCurrentStage)) {
            if (["stage1", "stage2"].includes(stage)) {
              this.$store.commit("CLEAR_YEAR_STAGE");
              return;
            }
          } else {
            if (stage === this.TimeLineYearCurrentStage) {
              this.$store.commit("CLEAR_YEAR_STAGE");
              return;
            }
          }
        } else {
          if (stage === this.TimeLineYearCurrentStage) {
            this.$store.commit("CLEAR_YEAR_STAGE");
            return;
          }
        }
      }
      this.$Bus.$emit("PLAY_VOICE");
      this.$store.commit("SET_YEAR_STAGE", stage);
    },
  },
};
</script>
<style lang='scss' scoped>
.time-line-box {
  width: calc(100% - 310px);
  height: 30px;
  padding: 0 90px;
  pointer-events: auto;
  user-select: none;
  .time-line {
    height: 1px;
    background-color: #fff;
  }
  .time-line-year {
    width: 100%;
    display: flex;
    height: 4px;
    background: rgba(255, 255, 255, 0.2);
    position: relative;
    @keyframes timeLineAnimate {
      0% {
        opacity: 0.2;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.2;
      }
    }
    .time-line-bar {
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      animation: timeLineAnimate 2s ease infinite;
    }
    .stage-box {
      position: relative;
      .stage-year-box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .year-box {
          font-family: "HELV";
          font-size: 18px;
          color: #b5b5b6;
          transition: color 0.3s;
          position: absolute;
          top: -30px;
          left: 0;
        }
        .stage-container {
          padding-left: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          .stage-btn {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: #b5b5b6;
            cursor: pointer;
            transition: all 0.3s;
            position: absolute;
            left: calc(50% + 14px);
            top: calc(50% - 7px);
            .text-box {
              color: #b5b5b6;
              font-size: 20px;
              letter-spacing: 1px;
              position: absolute;
              left: 50%;
              top: -24px;
              transform: translate(-50%, -50%);
              transition: all 0.3s;
              white-space: nowrap;
            }
            &:hover {
              background: #1fc9b7;
              .text-box {
                color: #1fc9b7;
              }
            }
          }
        }
      }
    }
    .actived {
      .stage-year-box {
        .year-box {
          color: #1fc9b7;
        }
        .stage-container {
          .stage-btn {
            background: #1fc9b7;
            .text-box {
              color: #1fc9b7;
              transform: translate(-50%, -50%) scale(1.1);
            }
          }
        }
      }
    }
    @keyframes breathAnimate {
      0% {
        transform: translate(-50%, -50%) scale(1);
      }
      25% {
        transform: translate(-50%, -50%) scale(0.8);
      }
      50% {
        transform: translate(-50%, -50%) scale(1);
      }
      75% {
        transform: translate(-50%, -50%) scale(0.8);
      }
      100% {
        transform: translate(-50%, -50%) scale(1);
      }
    }
    .breath-animate {
      .stage-year-box {
        .stage-container {
          .stage-btn {
            .text-box {
              animation: breathAnimate 3s ease infinite;
            }
          }
        }
      }
    }
  }
  .enhancer {
    padding: 0 60px;
    .stage-box {
      flex: 1;
      &:last-child {
        flex: 0;
      }
      .stage-btn {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #b5b5b6;
        cursor: pointer;
        transition: all 0.3s;
        position: absolute;
        top: -5px;
        left: -7px;
        .year-box {
          font-family: "HELV";
          font-size: 18px;
          color: #b5b5b6;
          transition: all 0.3s;
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .actived {
      .stage-btn {
        background: #1fc9b7;
        .year-box {
          font-family: "MOBOLD";
          color: #1fc9b7;
          font-size: 24px;
          transform: translateX(-50%) scale(1.1);
        }
      }
    }
    @keyframes breathAnimate1 {
      0% {
        transform: translateX(-50%) scale(1);
      }
      25% {
        transform: translateX(-50%) scale(0.8);
      }
      50% {
        transform: translateX(-50%) scale(1);
      }
      75% {
        transform: translateX(-50%) scale(0.8);
      }
      100% {
        transform: translateX(-50%) scale(1);
      }
    }
    .breath-animate {
      .stage-btn {
        .year-box {
          animation: breathAnimate1 3s ease infinite;
        }
      }
    }
  }
}
</style>