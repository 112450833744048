<template>
  <div class="fab-enhancer-product-view">
    <div class="page-title">
      <p class="title">
        Needs for cleaning is essential,<br /> while care needs need to be established in consumers’ mind.
      </p>
    </div>
    <div class="table-container">
      <!-- 背景线 -->
      <div class="grid-line-container">
        <div v-for="item in 8" :key="item" class="vertical-line"></div>
        <div class="horizontal-line"></div>
      </div>
      <div class="text-arrow-style-2 arrow-1" style="cursor: unset;">
        <div class="arrow-ahead">0</div>
        <p class="text-box">Softener was primarily for towels</p>
      </div>
      <!-- stage 1 -->
      <div
        class="text-arrow-style-2 arrow-2"
        :class="{actived: activedStage1}"
        @click="changeStage($event, 'stage1')"
      >
        <div class="arrow-ahead">1</div>
        <p class="text-box">
          Establish Fabric Care Needs: <br />Offer Comprehensive Protection For
          In-wear
        </p>
        <WaveButton @click="openDialog(1)" />
      </div>
      <BorderBox
        :lineColor="activedStage1 ? '#49DFE2' : '#898989'"
        :class="{actived: activedStage1}"
        class="border-text-box text-box-1"
      >
        <div class="text-container">
          <div class="text-box">
            <p class="text-style-1 margin-bottom-10">Concentrate format introduced</p>
          </div>
        </div>
      </BorderBox>
      <BorderBox
        :lineColor="activedStage1 ? '#49DFE2' : '#898989'"
        :class="{actived: activedStage1}"
        class="border-text-box text-box-5"
      >
        <div class="text-container">
          <div class="text-box">
            <p class="text-style-1 margin-bottom-10">Extend care from towel to clothes</p>
          </div>
        </div>
      </BorderBox>
      <BorderBox
        :lineColor="activedStage1 ? '#49DFE2' : '#898989'"
        class="border-text-box text-box-2"
        :class="{actived: activedStage1}"
      >
        <div class="text-container">
          <div class="text-box">
            <p class="text-style-1">Multiple care functions beyond softening</p>
          </div>
        </div>
      </BorderBox>
      <!-- stage 2 -->
      <div
        class="text-arrow-style-2 arrow-3"
        @click="changeStage($event, 'stage2')"
        :class="{actived: activedStage2}"
      >
        <div class="arrow-ahead">2</div>
        <p class="text-box">
          Add Odor Control Function:<br />Remove Bad Smell
        </p>
        <WaveButton @click="openDialog(2)" />
      </div>
      <BorderBox
        :lineColor="activedStage2 ? '#49DFE2' : '#898989'"
        class="border-text-box text-box-3"
        :class="{actived: activedStage2}"
      >
        <div class="text-container">
          <div class="text-box">
            <p class="text-style-1 margin-bottom-10">Odor control introduced in 2004</p>
            <p class="text-style-1 text-style-1-none">
              <span class="text-style-2 margin-bottom-10">Strong deo for large loads ( e.g. singles, less frequent wash )</span><br />
              <span class="text-style-2 margin-bottom-10">Active lifestyle (e.g. workout )</span>
            </p>
          </div>
        </div>
      </BorderBox>
      <!-- stage 3 -->
      <div
        class="text-arrow-style-2 arrow-4"
        @click="changeStage($event, 'stage3')"
        :class="{actived: activedStage3}"
      >
        <div class="arrow-ahead">3</div>
        <p class="text-box">
          Add Fragrance:<br />Add Good Smell    
        </p>
        <WaveButton @click="openDialog(3)" />
      </div>
      <BorderBox
        :lineColor="activedStage3 ? '#49DFE2' : '#898989'"
        class="border-text-box text-box-4"
        :class="{actived: activedStage3}"
      >
        <div class="text-container">
          <div class="text-box">
            <p class="text-style-1">Fragrance booms since 2009</p>
          </div>
        </div>
      </BorderBox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ViewAnimation from "@/mixins/ViewAnimation";
import BorderBox from "@/components/BorderBox";
import WaveButton from '@/components/WaveButton';

export default {
  name: "FabEnhancerProduct",
  mixins: [ViewAnimation],
  data() {
    return {
      mainTitle: "Fab Enhancer",
      subTitle: "Product Evolvement",
    };
  },

  components: {
    BorderBox,
    WaveButton,
  },

  mounted() {
  },

  beforeDestroy() {
  },

  computed: {
    ...mapGetters(["TimeLineYearCurrentStage"]),
    activedStage1() {
      return this.TimeLineYearCurrentStage === 'stage1';
    },
    activedStage2() {
      return this.TimeLineYearCurrentStage === 'stage2';
    },
    activedStage3() {
      return this.TimeLineYearCurrentStage === 'stage3';
    }
  },

  methods: {
    enterAfter() {
      this.$Bus.$emit("SET_STAGE_MODEL_ENHANCER_PRODUCT");
    },
    exitBefore() {
      this.$Bus.$emit("CLEAR_STAGE_MODEL");
    },
    changeStage(e, stage) {
      if ((e.target !== e.currentTarget && e.target.parentNode !== e.currentTarget) || e.target.className === 'wave-btn') return;
      this.$Bus.$emit('PLAY_VOICE');
      this.$store.commit("SET_YEAR_STAGE", stage);
    },
    openDialog(dialogName) {
      this.$Bus.$emit('PLAY_VOICE');
      if (dialogName === 1) {
        if (!this.activedStage1) {
          this.$store.commit("SET_YEAR_STAGE", 'stage1');
          return;
        }
      } else if(dialogName === 2) {
        if (!this.activedStage2) {
          this.$store.commit("SET_YEAR_STAGE", 'stage2');
          return;
        }
      } else if(dialogName === 3) {
        if (!this.activedStage3) {
          this.$store.commit("SET_YEAR_STAGE", 'stage3');
          return;
        }
      }
      this.$Bus.$emit(`DRIVER_DIALOG_OPEN`);
      this.$Bus.$emit('CHANGE_DIALOG_INDEX', dialogName);
    }
  },
};
</script>
<style lang='scss' scoped>
.fab-enhancer-product-view {
  width: 100%;
  height: 100%;
  .page-title {
    width: 100%;
    height: 102px;
    padding: 0 100px 0 40px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background: linear-gradient(
      90deg,
      rgba(31, 199, 182, 0.5),
      rgba(31, 199, 182, 0.1)
    );
    .title {
      color: rgba(200, 202, 202, 0.9);
      font-size: 36px;
    }
  }
  .table-container {
    height: calc(100% - 122px);
    padding: 30px 0 30px;
    position: relative;
    .grid-line-container {
      width: 100%;
      height: 100%;
      padding-bottom: 90px;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: space-between;
      .vertical-line {
        width: 1px;
        height: 100%;
        background: radial-gradient(
          rgba(38, 240, 222, 0.4),
          rgba(86, 133, 128, 0.1) 70%
        );
      }
      .horizontal-line {
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 90px;
        left: 0;
        background: radial-gradient(
          rgba(38, 240, 222, 0.4),
          rgba(31, 199, 182, 0.01) 80%
        );
        z-index: 10;
      }
    }
    .text-arrow-style {
      display: flex;
      align-items: center;
      color: #898989;
      font-size: 20px;
      padding-left: 42px;
      background-image: url("~@/assets/images/arrow-big-gray.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transition: all 0.3s;
      cursor: pointer;
      user-select: none;
    }
    .text-arrow-style.actived {
      color: #1fc9b7;
      background-image: url("~@/assets/images/arrow-big-color.png");
    }
    .text-arrow-style-2 {
      color: #898989;
      font-size: 20px;
      display: flex;
      align-items: center;
      background-image: url("~@/assets/images/arrow-big-gray.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transition: all 0.3s;
      cursor: pointer;
      user-select: none;
      .arrow-ahead {
        flex-shrink: 0;
        width: 110px;
        height: 100%;
        background-image: url("~@/assets/images/arrow-ahead-gray.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        transition: all 0.3s;
        cursor: pointer;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 30px;
        font-size: 30px;
        color: #898989;
        &:after {
          content: "";
          display: block;
          width: 8px;
          height: 10px;
          background-image: url("~@/assets/images/right-arrow-gray.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-left: 10px;
        }
      }
      .text-box {
        padding-right: 100px;
      }
      .wave-btn-com {
        position: absolute;
        right: 90px;
        ::v-deep .view-more {
          display: none;
        }
      }
    }
    .text-arrow-style-2.actived {
      color: #1fc9b7;
      background-image: url("~@/assets/images/arrow-big-color.png");
      .arrow-ahead {
        color: #fff;
        background-image: url("~@/assets/images/arrow-ahead-color.png");
        &:after {
          background-image: url("~@/assets/images/right-arrow.png");
        }
      }
      .wave-btn-com {
        ::v-deep .view-more {
          display: block;
        }
      }
    }
    .arrow-1 {
      width: 42.8%;
      min-width: 400px;
      height: 60px;
      position: absolute;
      left: 50px;
      top: 0;
    }
    .arrow-2 {
      width: calc(57% - 50px);
      height: 60px;
      position: absolute;
      left: calc(42.8% + 50px);
      top: 0;
    }
    .arrow-3 {
      width: 42.8%;
      height: 60px;
      position: absolute;
      left: 57%;
      top: 210px;
    }
    .arrow-4 {
      width: 28.57%;
      height: 60px;
      position: absolute;
      left: 71.4%;
      top: 380px;
    }
    .border-text-box {
      transition: all 0.3s;
      position: absolute;
      transform-origin: 0 0;
      .text-container {
        display: flex;
        justify-content: flex-start;
        padding: 10px;
      }
      .text-style-1 {
        padding-left: 14px;
        position: relative;
        font-size: 12px;
        color: #898989;
        letter-spacing: 1px;
        transition: all 0.3s;
        &:before {
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #898989;
          position: absolute;
          top: 4px;
          left: 0;
          transition: all 0.3s;
        }
      }
      .text-style-1-none {
        &:before {
          content: unset;
        }
      }
      .text-style-2 {
        padding-left: 16px;
        position: relative;
        font-size: 12px;
        color: #898989;
        letter-spacing: 1px;
        transition: all 0.3s;
        &:before {
          content: "";
          display: block;
          width: 9px;
          height: 10px;
          background-image: url("~@/assets/images/right-arrow-gray.png");
          background-repeat: no-repeat;
          background-size: auto 100%;
          position: absolute;
          top: 4px;
          left: 0;
          transition: all 0.3s;
        }
      }
    }
    .border-text-box.actived {
      .text-style-1 {
        color: #fff;
        &:before {
          background: #1fc9b7;
        }
      }
      .text-style-2 {
        color: #fff;
        &:before {
          background-image: url("~@/assets/images/right-arrow.png");
        }
      }
    }
    .text-box-1 {
      width: 600px;
      left: 48%;
      top: 68px;
    }
    .text-box-5 {
      width: 600px;
      left: 48%;
      top: 116px;
    }
    .text-box-2 {
      width: 600px;
      left: 48%;
      top: 164px;
    }
    .text-box-3 {
      width: 500px;
      left: calc(57% + 40px);
      top: 280px;
    }
    .text-box-4 {
      width: calc(28.57% - 20px);
      left: calc(71.4% + 20px);
      top: 460px;
    }
  }
}
</style>