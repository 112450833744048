<template>
  <div class="section-animation-engine">
    <div class="animation-wrapper">
      <transition name="bg-fade">
        <div v-if="bgShow" class="bg-wrapper" :class="{'img-is-loaded': this.backgroundImage}" :style="{
          backgroundImage
        }"></div>
      </transition>
      <div class="content-wrapper">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      imgSrc: '',
      loadImgTimer: null,
      bgShow: true
    };
  },

  mounted() {
    this.$Bus.$on("VIEW_EXIT", ({ model, menu }) => {
      this.bgShow = false;
    });
    this.loadImg();
  },

  beforeDestroy() {
    clearTimeout(this.loadImgTimer);
  },

  components: {},

  computed: {
    backgroundImage() {
      if (this.imgSrc) {
        return `url(${this.imgSrc})`;
      }
      return null;
    }
  },

  methods: {
    loadImg() {
      if (this.img) {
        this.loadImgTimer = setTimeout(() => {
          const imger = new Image();
          imger.onload = () => {
            setTimeout(() => {
              this.imgSrc = this.img
            }, 200)
          }
          imger.src = this.img;
        }, 300);
      }
    }
  }
};

</script>
<style lang='scss' scoped>
.section-animation-engine {
  width: 100%;
  height: 100%;
  .animation-wrapper {
    width: 100%;
    height: 100%;
    padding-left: 310px;
    transform: translate3d(0,0,0);
    position: absolute;
    top: 0;
    left: 0;
    // .bg-fade-enter-active,
    // .bg-fade-leave-active {
    //   transition: all .6s;
    // }
    .bg-fade-enter, .bg-fade-leave-to {
      opacity: 0 !important;
    }
    .bg-wrapper {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      transition: opacity .7s cubic-bezier(.694,0,.335,1), transform 7s linear;
      opacity: 0;
    }
    .img-is-loaded {
      opacity: 1;
      transform: scale(1.1);
    }
    .content-wrapper {
      width: 100%;
      height: 100%;
      padding: 170px 90px 0 80px;
      position: relative;
      
    }
  }
}
</style>