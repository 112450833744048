<template>
  <div class="sider-bar-box">
    <div class="logo-box">
      <div class="logo"></div>
    </div>
    <div class="nav-container">
      <nav class="sider-menu-nav">
        <div class="nation-select-box">
          <div class="flag-box"></div>
          <div class="select-box" :class="{'is-open': openSelect }" @click="openSelect=!openSelect">
            <div ref="selectBox" class="slect-taregt-box"></div>
            <span class="select-text">Japan Market</span>
            <div class="select-arrow"></div>
            <div class="slect-options">Japan Market</div>
          </div>
        </div>
        <div class="check-box-group" :class="[model]">
          <div class="check-box-item" @click="modelChange('FabClean')">Fab Clean</div>
          <div class="check-box-item" @click="modelChange('FabEnhancer')">Fab Enhancer</div>
        </div>
        <div class="menu-box-group">
          <div class="menu-box-item" :class="{actived: menu === 'Economy'}" @click="menuChange('Economy')">Economy Trend</div>
          <div class="menu-box-item" :class="{actived: menu === 'Impact'}" @click="menuChange('Impact')">Impact on <br /> Doing Laundry</div>
          <div class="menu-box-item" :class="{actived: menu === 'Product'}" @click="menuChange('Product')">Product Evolvement</div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'SiderBar',
  data () {
    return {
      transitionAnimateCompleted: false,
      openSelect: false
    };
  },

  components: {},

  computed: {
    ...mapGetters(['model', 'menu'])
  },

  mounted() {
    window.addEventListener('click', (e) => {
      if(e.target != this.$refs.selectBox) {
        this.openSelect = false;
      }
    })
    // 注册事件：view 切换动画执行完
    this.$Bus.$on('VIEW_ANIMATE_COMPLETED', () => {
      this.transitionAnimateCompleted = true;
    })
  },

  methods: {
    viewChange(menu) {
      if (!this.transitionAnimateCompleted) return;
      this.$Bus.$emit('PLAY_VOICE');
      this.transitionAnimateCompleted = false;
      this.$Bus.$emit('SET_TIME_LINE_HIDE');
      setTimeout(() => {
        this.$Bus.$emit('VIEW_EXIT', {model: this.model, menu});
      }, 500)
    },
    modelChange(val) {
      if (!this.transitionAnimateCompleted) return;
      this.$Bus.$emit('DRIVER_DIALOG_CLOSE');
      this.$store.commit('SET_MODEL', val);
      this.viewChange(this.menu);
    },
    menuChange(menu) {
      if (menu === this.menu) return;
      this.$Bus.$emit('DRIVER_DIALOG_CLOSE');
      this.viewChange(menu);
    }
  }
};

</script>
<style lang='scss' scoped>
.sider-bar-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top:0;
    right:0;
    width: 1px;
    height: 100%;
    background: radial-gradient(rgba(31, 199, 182, 1) 1%, rgba(31, 199, 182, .1) 40%, rgba(31, 199, 182, .1) 70%);
  }
  .logo-box {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      width: 160px;
      height: 68px;
      margin-right: 20px;
      background-image: url('~@/assets/images/logo.png');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
  
  .nav-container {
    flex-grow: 1;
    padding: 40px 30px 100px 42px;
    pointer-events: auto;
    .sider-menu-nav {
      height: 100%;
      color: #fff;
      .nation-select-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 36px;
        .flag-box {
          width: 50px;
          height: 34px;
          background-image: url('~@/assets/images/Japanese-national-flag.png');
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
        }
        .select-box {
          width: 166px;
          height: 34px;
          padding: 0 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          background: linear-gradient(90deg, rgba(18,94,85,.7), rgba(18,94,85,.2));
          cursor: pointer;
          .slect-taregt-box {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left:0;
            opacity: 0;
            z-index: 10;
          }
          .select-text {
            color: #fff;
            font-size: 13px;
          }
          .select-arrow {
            width: 10px;
            height: 12px;
            margin-top: 2px;
            background-image: url('~@/assets/images/right-arrow.png');
            background-size: contain;
            background-repeat: no-repeat;
            transition: all .3s;
            transform: rotate(90deg);
          }
          .slect-options {
            width: 100%;
            height:0;
            color: #fff;
            font-size: 13px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 34px;
            left:0;
            z-index: 100;
            background: rgba(18,94,85,1);
            &:hover {
              background: #125E55;
            }
          }
        }
        .is-open {
          .select-arrow {
            transform: rotate(270deg);
          }
          .slect-options {
            height:40px;
          }
        }
      }
      .check-box-group {
        width: 236px;
        height: 40px;
        display: flex;
        margin-bottom: 40px;
        position: relative;
        &:after{
          content: '';
          display: block;
          width: 50%;
          height: 100%;
          background: rgba(31, 199, 182, 1);
          transition: left .3s;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
        }
        .check-box-item {
          width: 50%;
          height: 100%;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all .3s;
          cursor: pointer;
          background: rgba(31, 199, 182, .2);
          position: relative;
          z-index: 10;
          &:hover {
            color: rgba(31, 199, 182, 1);
          }
        }
      }
      .FabClean {
        &:after {
          left: 0;
        }
        .check-box-item:first-child {
          &:hover {
            color: #fff;
          }
        }
      }
      .FabEnhancer {
        &:after {
          left: 50%;
        }
        .check-box-item:last-child{
          &:hover {
            color: #fff;
          }
        }
      }
      .menu-box-group {
        .menu-box-item {
          padding: 20px 0;
          text-align: right;
          transition: all .3s;
          cursor: pointer;
          font-size: 18px;
          line-height: 36px;
          margin-bottom: 20px;
          letter-spacing: 1px;
          &:hover {
            color: rgba(31, 199, 182, 1);
          }
        }
        .actived {
          color: rgba(31, 199, 182, 1);
        }
      }
    }
  }
}
</style>