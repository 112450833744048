<template>
  <div class="main-content">
    <div class="event-wrapper">
      <PreNextCom v-if="preNextShow" />
      <EnterAnimationCom />
      <SectionAnimation
        key="FabCleanEconomy"
        v-if="currentView === 'FabCleanEconomy'"
        :img="require('@/assets/images/161634136761_.pic_hd.jpg')"
      >
        <FabCleanEconomy />
      </SectionAnimation>
      <SectionAnimation
        key="FabCleanImpact"
        v-if="currentView === 'FabCleanImpact'"
      >
        <FabCleanImpact />
      </SectionAnimation>
      <SectionAnimation
        key="FabCleanProduct"
        v-if="currentView === 'FabCleanProduct'"
      >
        <FabCleanProduct />
      </SectionAnimation>
      

      <SectionAnimation
        key="FabEnhancerEconomy"
        v-if="currentView === 'FabEnhancerEconomy'"
        :img="require('@/assets/images/161634136761_.pic_hd.jpg')"
      >
        <FabEnhancerEconomy />
      </SectionAnimation>
      <SectionAnimation
        key="FabEnhancerImpact"
        v-if="currentView === 'FabEnhancerImpact'"
      >
        <FabEnhancerImpact />
      </SectionAnimation>
      <SectionAnimation
        key="FabEnhancerProduct"
        v-if="currentView === 'FabEnhancerProduct'"
      >
        <FabEnhancerProduct />
      </SectionAnimation>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PreNextCom from '@/components/PreNextCom.vue';
import EnterAnimationCom from './EnterAnimationCom.vue';
import SectionAnimation from "./SectionAnimation.vue";
import FabCleanEconomy from "@/views/FabCleanEconomy/FabCleanEconomy.vue";
import FabCleanImpact from '@/views/FabCleanImpact/FabCleanImpact.vue';
import FabCleanProduct from '@/views/FabCleanProduct/FabCleanProduct.vue';
import FabEnhancerEconomy from '@/views/FabEnhancerEconomy/FabEnhancerEconomy.vue';
import FabEnhancerImpact from '@/views/FabEnhancerImpact/FabEnhancerImpact.vue';
import FabEnhancerProduct from '@/views/FabEnhancerProduct/FabEnhancerProduct.vue';

export default {
  data() {
    return {
      currentView: '',
      preNextShow: false
    };
  },

  components: {
    PreNextCom,
    EnterAnimationCom,
    SectionAnimation,
    FabCleanEconomy,
    FabCleanImpact,
    FabCleanProduct,
    FabEnhancerEconomy,
    FabEnhancerImpact,
    FabEnhancerProduct
  },

  computed: {
    ...mapGetters(["model", "menu"]),
  },

  mounted() {
    // 注册事件：pre-next-btn 显示
    this.$Bus.$on('SHOW_PRE_NEXT_BUTTON', () => {
      this.preNextShow = true;
    })
    // 注册事件：pre-next-btn 隐藏
    this.$Bus.$on('HIDE_PRE_NEXT_BUTTON', () => {
      this.preNextShow = false;
    })
    // 转场动画结束切换视图
    this.$Bus.$on('CHANGE_VIEW', (viewName) => {
      this.$store.commit("CLEAR_YEAR_STAGE");
      this.changeView(viewName);
    })
  },

  methods: {
    changeView(viewName) {
      this.currentView = viewName;
    }
  },
};
</script>
<style lang='scss' scoped>
.main-content {
  width: 100%;
  height: 100%;
  .event-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    perspective: 20px;
    transform: translate3d(0px, 0px, 0px);
  }
}
</style>