<template>
  <div class="cmi-header">
    <h1 class="main-title">MARKET EVOLVEMENT</h1>
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  components: {},

  mounted() {
  },

  methods: {}
};

</script>
<style lang='scss' scoped>
.cmi-header {
  width: 100%;
  height: 130px;
  position: fixed;
  top:0;
  left: 0;
  z-index: 500;
  padding-left: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(31, 199, 182, .1) 60%, transparent);
  .main-title {
    line-height: 30px;
    font-size: 40px;
    font-weight: 700;
    color: transparent;
    // -webkit-box-reflect: below 0 -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(250, 250, 250, 0.2)));
    position: relative;
    width: 793px;
    height: 164px;
    transform: translateY(20px);
    background-image: url('~@/assets/images/light-title.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}
</style>